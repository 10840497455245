import {
  COMMON_PLATFORM_NAME,
  CUSTOM_WINDOW_EVENT,
  ClickEvent,
  CustomWindowEvent,
  emitCustomActorEvent,
  getActiveMonitor,
  getMousePosition,
  internalWorkspace
} from '@valstro/workspace';
import { commonPopoverSchemaOverrides, CommonPopoverActorSchemaOverrides } from './popover.common.actor';
import {
  COMMON_POPOVER,
  DEFAULT_POPOVER_OPTIONS,
  EMPTY_POPOVER_CTX,
  OpenPopoverOptions,
  TAURI_POPOVER_WIN_CTX
} from './popover.contracts';
import { getCurrentWebviewWindow } from '@tauri-apps/api/webviewWindow';
import { calculatePopoverBounds } from './popover.util';
import { listen, Event } from '@tauri-apps/api/event';

export const TAURI_POPOVER_ACTOR_NAME = 'tauri-popover-window';

export const commonTauriPopoverSchemaOverrides: CommonPopoverActorSchemaOverrides = (prevSchema) => ({
  ...prevSchema,
  ...commonPopoverSchemaOverrides(prevSchema),
  initialContext: async (ctx) => ({
    ...(await prevSchema.initialContext(ctx)),
    ...TAURI_POPOVER_WIN_CTX
  }),
  type: COMMON_POPOVER.TYPE,
  name: TAURI_POPOVER_ACTOR_NAME,
  supportedPlatforms: [COMMON_PLATFORM_NAME.TAURI],
  selectStrategy: (ctx) => {
    // When selecting the root actor, select this actor if the tauri window ID matches the pre-defined auth window ID
    if (ctx.location === 'root' && ctx.platformName === COMMON_PLATFORM_NAME.TAURI) {
      return getCurrentWebviewWindow().label.includes(COMMON_POPOVER.ID_BASE);
    }

    return true;
  },
  operations: (api, workspace) => {
    const prevOperations = prevSchema.operations(api, workspace);
    return {
      ...prevOperations,
      updateContext: async (ctx) => {
        await api.updateContext(ctx);
      },
      setComponentProps: async (ctx) => {
        await api.updateContext({
          componentProps: ctx
        });
      },
      updateComponentProps: async (ctx) => {
        const currentProps = api.getContext().componentProps || {};
        await api.updateContext({
          componentProps: {
            ...currentProps,
            ...ctx
          }
        });
      },
      getMousePosition: async () => {
        const { x, y } = await getMousePosition();
        return { x, y };
      },
      discardPopover: async () => {
        await api.operations.setPosition({
          x: TAURI_POPOVER_WIN_CTX.x || 20_000,
          y: TAURI_POPOVER_WIN_CTX.y || 20_000
        });
        await api.updateContext(EMPTY_POPOVER_CTX);
      },
      openPopover: async ({
        windowId,
        popoverId,
        componentId,
        align,
        alignOffset,
        componentProps,
        width,
        height,
        side,
        sideOffset,
        rectWithScaling,
        innerPosition,
        ariaLabel,
        popoverTestId,
        autoSizeHeight,
        autoSizeWidth,
        autoSizeWidthToTrigger,
        autoSizePaddingWidth,
        autoSizePaddingHeight,
        focusOnOpen,
        enterDelay,
        exitDelay,
        trigger,
        closingStrategy,
        transparent,
        autoSizeTriggerResizePixelSensitivity
      }: OpenPopoverOptions) => {
        const monitor = await getActiveMonitor();
        const scaleFactor = monitor.scaleFactor;
        await api.updateContext({
          autoSizeTriggerResizePixelSensitivity,
          width,
          height,
          windowId,
          componentId,
          componentProps,
          ariaLabel,
          popoverTestId,
          popoverId,
          autoSizeHeight: autoSizeHeight ?? DEFAULT_POPOVER_OPTIONS.autoSizeHeight,
          autoSizeWidth: autoSizeWidth ?? DEFAULT_POPOVER_OPTIONS.autoSizeWidth,
          autoSizeWidthToTrigger: autoSizeWidthToTrigger ?? DEFAULT_POPOVER_OPTIONS.autoSizeWidthToTrigger,
          autoSizePaddingWidth,
          autoSizePaddingHeight,
          focusOnOpen: focusOnOpen ?? DEFAULT_POPOVER_OPTIONS.focusOnOpen,
          enterDelay: enterDelay ?? DEFAULT_POPOVER_OPTIONS.enterDelay,
          exitDelay: exitDelay ?? DEFAULT_POPOVER_OPTIONS.exitDelay,
          transparent: transparent ?? DEFAULT_POPOVER_OPTIONS.transparent,
          trigger: trigger ?? DEFAULT_POPOVER_OPTIONS.trigger,
          closingStrategy: closingStrategy ?? DEFAULT_POPOVER_OPTIONS.closingStrategy
        });

        if (!monitor) {
          throw new Error('No monitor found');
        }

        if (focusOnOpen === true) {
          await getCurrentWebviewWindow().setFocus();
        }

        // Use scaleFactor to normalize the position and size to physical pixels
        const { x: physicalWinX, y: physicalWinY } = innerPosition;
        const x = physicalWinX + rectWithScaling.x;
        const y = physicalWinY + rectWithScaling.y;

        const bounds = calculatePopoverBounds(
          {
            x,
            y,
            width: rectWithScaling.width,
            height: rectWithScaling.height,
            windowX: monitor.position.x,
            windowY: monitor.position.y,
            windowWidth: monitor.size.width,
            windowHeight: monitor.size.height,
            scaleFactor
          },
          {
            align,
            alignOffset,
            height,
            width,
            side,
            sideOffset
          }
        );

        await api.operations.setSizeAndPosition(
          {
            width: bounds.width,
            height: bounds.height,
            useLogicalPixels: false
          },
          {
            x: bounds.x,
            y: bounds.y,
            useLogicalPixels: false
          }
        );

        await api.updateContext({
          isOpen: true,
          renderedSide: bounds.renderedSide,
          renderedAlign: bounds.renderedAlign
        });

        return {
          width: bounds.width,
          height: bounds.height,
          x: bounds.x,
          y: bounds.y
        };
      }
    };
  },
  events: async (api, workspace) => {
    const { getContext, getDefinition } = api;
    const eventsTransport = internalWorkspace(workspace)._eventsTransport;
    const emitClickOutside = async () => {
      if (!getContext().isFocused || !getContext().isVisible) {
        return;
      }

      const mousePos = await getMousePosition();

      emitCustomActorEvent<ClickEvent>(eventsTransport, getDefinition(), {
        type: 'click',
        payload: {
          x: mousePos.x,
          y: mousePos.y,
          type: 'outside'
        }
      }).catch(console.error);
    };

    const globalBlurUnsub = await listen(
      CUSTOM_WINDOW_EVENT,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      async (e: Event<CustomWindowEvent>) => {
        const actorId = e.payload.windowLabel;
        if (e.payload.event !== 'tauri://blur' || !actorId.includes(COMMON_POPOVER.ID_BASE)) {
          return;
        }

        if (actorId !== getDefinition().id) {
          return;
        }

        await emitClickOutside();
      },
      { target: { kind: 'Any' } }
    );

    const unsub = await prevSchema?.events?.(api, workspace);
    return () => {
      unsub?.();
      globalBlurUnsub();
    };
  }
});
