import * as Sentry from '@sentry/react';
import { FormBuilder, FORM_EVENT_TYPE, FormValidationFeedbackLevel } from '@oms/frontend-foundation';
import type { BugReporterFormInput, BugReporterFormOutput } from './bug-reporter.form-common';
import { bugReporterFormContract } from './bug-reporter.form-contract';
import type { BugReporterFormContractType, BugReporterFormValues } from './bug-reporter.form-contract';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { globalSentrySignal } from '@app/data-access/memory/sentry.signal';

export const bugReporterFormBuilder = FormBuilder.create<BugReporterFormInput, BugReporterFormOutput>(
  'bug-reporter-form'
)
  .contract<BugReporterFormContractType>(bugReporterFormContract)
  .type('bug-reporter')
  .sanitizer((s) =>
    s
      .input(async function sanitize(_input, ctx) {
        const service = ctx.container.resolve(AuthService);
        const name = service.currentUser?.name;
        const email = service.currentUser?.email;
        const lastException = globalSentrySignal.get();

        const formValues: BugReporterFormValues = {
          name,
          email,
          errorMessage: lastException?.lastException?.message,
          event_id: lastException?.lastException?.eventId
        };

        return formValues;
      })
      .output(function sanitize(formValues) {
        if (!formValues.name) return;
        if (!formValues.email) return;

        const output: BugReporterFormOutput = {
          name: formValues.name,
          email: formValues.email,
          message: formValues.message ?? '',
          event_id: formValues.event_id,
          screenshot: formValues.screenshot,
          captureContext: formValues.captureContext
        };

        return output;
      })
  )
  .change(async (event, ctx) => {
    switch (event.type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const { captureContext, email, name, message, event_id, screenshot } = event.payload.output;
        const attachments = screenshot ? [await fileToAttachment(screenshot)] : [];

        const feedback = await Sentry.sendFeedback(
          {
            name,
            email,
            message
          },
          {
            event_id,
            captureContext: {
              ...captureContext
              // TODO: Add useful info like loki logs URL based on env etc.
            },
            attachments
          }
        );

        if (feedback) {
          ctx.notify({
            type: 'CLOSE'
          });
        } else {
          ctx.notify({
            type: 'SET_FEEDBACK',
            payload: {
              feedback: [
                {
                  message: 'Failed to send feedback',
                  code: 'feedback-failed',
                  level: FormValidationFeedbackLevel.Error
                }
              ]
            }
          });
        }
      }
    }
  });

export type BugReporterFormBuilderType = typeof bugReporterFormBuilder;

export default bugReporterFormBuilder;

async function fileToAttachment(file: File) {
  const arrayBuffer = await file.arrayBuffer();
  const data = new Uint8Array(arrayBuffer);
  const attachment = {
    data,
    filename: file.name,
    contentType: file.type || 'application/octet-stream'
  };
  return attachment;
}
