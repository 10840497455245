import { FormSummary } from '@oms/frontend-foundation';
import React, { useMemo } from 'react';
import type { ExecutionRow } from '@oms/generated/frontend';

export const ExecutionDetailsSummary = React.memo(({ execution }: { execution: ExecutionRow }) => {
  const data = useMemo(() => {
    return [];
  }, [execution]);

  return <FormSummary labeledData={data} />;
});
