import type { AppState as AppStateType } from '@app/common/app/app.contracts';
import { APP_STATE_TYPE, DEFAULT_APP_STATE } from '@app/common/app/app.contracts';
import type { AuthClientState } from '@app/common/auth/keycloak.types';
import type { DataAccessState } from '@app/common/data-access/data-access.contracts';
import { useService } from '@oms/frontend-foundation';
import { createLogger } from '@oms/shared/util';
import { useObservableState } from 'observable-hooks';
import type { Observable, Subscription } from 'rxjs';
import { combineLatest, distinctUntilChanged, filter, map } from 'rxjs';
import type { Disposable } from 'tsyringe';
import { inject, singleton } from 'tsyringe';
import { AuthSignal } from './auth.signal';
import { DataAccessSignal } from './data-access.signal';
import { SyncronisationSignal, SyncronisationState } from './syncronisation.signal';
import { testScoped } from '@app/workspace.registry';
import * as Sentry from '@sentry/react';
import { UpdaterSignal } from './updater.signal';
import type { UpdaterState } from './updater.signal';

const l = createLogger({
  name: 'app.stream'
});

/**
 * AppState
 *
 * Combines AuthSignal, DataAccessSignal, and SyncronisationSignal to determine the current state of the application
 *
 * @usage
 * ```ts
 * const appState = container.resolve(AppState);
 * const subscription = appState.$.subscribe((state) => {
 *  l.info('App state changed', state);
 * });
 * ```
 *
 * @usage
 * ```ts
 * constructor(@inject(AppState) private appState: AppState) {
 *  const subscription = this.appState.$.subscribe((state) => {
 *    l.info('App state changed', state);
 *  });
 *
 * }
 */
@testScoped
@singleton()
export class AppState implements Disposable {
  private subscriptions: Subscription[] = [];
  private static appStateTimeoutMs = 60_000;
  private appStateTimeout: NodeJS.Timeout | undefined;
  public $: Observable<AppStateType>;
  public currentState: AppStateType = this.DEFAULT_STATE;

  constructor(
    @inject(AuthSignal) private authState: AuthSignal,
    @inject(DataAccessSignal) private dataAccessSignal: DataAccessSignal,
    @inject(SyncronisationSignal) private syncronisationSignal: SyncronisationSignal,
    @inject(UpdaterSignal) private updaterSignal: UpdaterSignal
  ) {
    this.$ = combineLatest([
      this.authState.signal.$,
      this.dataAccessSignal.signal.$,
      this.syncronisationSignal.signal.$,
      this.updaterSignal.signal.$
    ]).pipe(
      map(([auth, dataAccess, syncronisation, updater]) => {
        const appState: AppStateType = {
          state: this.getAppStateType(auth, dataAccess, syncronisation, updater),
          auth,
          dataAccess,
          user: auth.tokenParsed ?? null,
          syncronisation,
          updater
        };

        this.handleAppStateTimeout(appState);

        this.currentState = appState;
        return appState;
      }),
      distinctUntilChanged()
    );

    // Debugging purposes (long-lived subscriptions)
    this.subscriptions.push(
      this.dataAccessSignal.signal.$.subscribe((state) => {
        l.debug('Data access state changed', state);
      })
    );

    this.subscriptions.push(
      authState.signal.$.subscribe((state) => {
        l.debug('Auth state changed', state);
      })
    );

    this.subscriptions.push(
      this.syncronisationSignal.signal.$.subscribe((state) => {
        l.debug('Syncronisation state changed', state);
      })
    );

    this.subscriptions.push(
      this.updaterSignal.signal.$.subscribe((state) => {
        l.debug('Updater state changed', state);
      })
    );

    this.subscriptions.push(
      this.$.subscribe((state) => {
        l.debug('App state changed', state.state, state);
      })
    );
  }

  public get DEFAULT_STATE() {
    return DEFAULT_APP_STATE;
  }

  public get ready$() {
    return this.$.pipe(filter((state) => state.state === 'Ready'));
  }

  public dispose() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private handleAppStateTimeout(appState: AppStateType) {
    const differentAppStates = this.currentState.state !== appState.state;

    if (differentAppStates && this.appStateTimeout) {
      l.debug(`Clear app state timeout because '${this.currentState.state}'->'${appState.state}'`);
      clearTimeout(this.appStateTimeout);
    }

    const possibleTimeoutStates: AppStateType['state'][] = ['Connecting', 'Disconnected', 'Syncronising'];
    const shouldAlertSentry = possibleTimeoutStates.includes(appState.state);

    l.debug(`Setup timeout for app state '${appState.state}'?`, {
      shouldAlertSentry,
      differentAppStates,
      appState: appState.state
    });

    if (shouldAlertSentry && differentAppStates) {
      this.appStateTimeout = setTimeout(() => {
        l.debug('Alerting Sentry', {
          appState
        });

        Sentry.captureMessage(
          `App timeout in state ${appState.state} for ${AppState.appStateTimeoutMs / 1000}s`,
          (scope) => {
            appState.syncronisation.syncronisationErrorMessage &&
              scope.captureMessage(appState.syncronisation.syncronisationErrorMessage);

            appState.updater.updateErrorMessage && scope.captureMessage(appState.updater.updateErrorMessage);

            scope.setLevel('error');

            return scope;
          }
        );
      }, AppState.appStateTimeoutMs);
    }
  }

  private getAppStateType(
    auth: AuthClientState,
    dataAccess: DataAccessState,
    syncronisation: SyncronisationState,
    updater: UpdaterState
  ) {
    const isAuthenticating = !auth.isAuthenticated && !auth.isReady;
    const isAuthorized = auth.isAuthenticated && auth.isReady;
    const isUnauthorized = !auth.isAuthenticated && auth.isReady;
    const isConnecting = dataAccess === 'connecting';
    const isConnected = dataAccess === 'connected';
    const isDisconnected = dataAccess === 'interrupted';
    const isSyncronising = syncronisation.isSyncronising;
    const isSyncronised = syncronisation.isSyncronised;
    const isCheckingForUpdates = updater.isCheckingForUpdates;
    const isUpdating = updater.hasUpdateAvailable || updater.isUpdating;

    switch (true) {
      case isCheckingForUpdates:
        return APP_STATE_TYPE.CHECKING_FOR_UPDATES;
      case isUpdating:
        return APP_STATE_TYPE.UPDATING;
      case isAuthenticating:
        return APP_STATE_TYPE.AUTHENTICATING;
      case isUnauthorized:
        return APP_STATE_TYPE.UNAUTHORIZED;
      case isConnecting:
        return APP_STATE_TYPE.DATA_ACCESS_CONNECTING;
      case isSyncronising:
        return APP_STATE_TYPE.SYNCRONISING;
      case isAuthorized && isConnected && isSyncronised:
        return APP_STATE_TYPE.READY;
      case isConnected:
        return APP_STATE_TYPE.DATA_ACCESS_CONNECTED;
      case isDisconnected:
        return APP_STATE_TYPE.DATA_ACCESS_DISCONNECTED;
      default:
        return APP_STATE_TYPE.IDLE;
    }
  }
}

/**
 * Subscribe to the AppState stream
 *
 * @usage
 * ```ts
 * const appState = useAppStateStream();
 * l.info('App state changed', appState);
 * ```
 *
 * @returns AppState stream
 */
export function useAppStateStream(): AppStateType {
  const service = useService(AppState);
  return useObservableState(service.$, service.DEFAULT_STATE);
}
