import type { ExecutionRow } from '@oms/generated/frontend';
import {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';

const executionsGridDefaultColumn: EnrichedColumnDef = {
  extends: 'generic.default',
  floatingFilter: true
};
export const executionsColumns = {
  id: { extends: 'orders.id' },
  status: {
    extends: 'orders.tradeStatus'
  },
  executedPrice: { extends: 'orders.price', format: 'price-trade' },
  side: {
    extends: 'orders.side'
  },
  executedQuantity: { extends: 'orders.quantity' },
  tradeCounterParty: { extends: 'generic.text', width: 168 },
  tradeContraAccountName: { extends: 'generic.text', width: 180 },
  lastMarket: { extends: 'generic.text' },
  lastTradeTime: { extends: 'generic.dateTime' },
  trader: { extends: 'generic.text' },
  capacity: { extends: 'orders.orderCapacity' },
  normalizedLiquidityIndicator: { extends: 'generic.text' },
  rawLiquidityIndicator: { extends: 'generic.text' },
  tradeId: { extends: 'orders.id' },
  settleType: {
    extends: 'orders.settleType',
    hide: true
  },
  settleDate: {
    extends: 'generic.date',
    minWidth: 160,
    hide: true
  },
  settleFxRate: {
    extends: 'orders.price',
    format: 'price-fx'
  }
} satisfies ColDefMap<ExecutionRow>;

const executionsColumnLibrary: TableServerColumnLibrary<ExecutionRow> = {
  defaultColumn: executionsGridDefaultColumn,
  columns: executionsColumns
};

export const buildExecutionsGridColumnDefs = () =>
  buildColumnDefs(executionsColumnLibrary, 'tradeViewExecutions');
