import { useMemo } from 'react';
import type { ExecutionRow } from '@oms/generated/frontend';
import { APP_DISPLAY_FIELD_COMPONENT_TYPE, AppDisplayGrid } from '@oms/frontend-foundation';
import type { AppDisplayGridProps } from '@oms/frontend-foundation';
import { Box, format, Text } from '@oms/shared-frontend/ui-design-system';

import { paneStyle as sx } from '@app/widgets/common/styles/trading.styles';

const getDisplayGridItems = (execution: ExecutionRow): AppDisplayGridProps['items'] => {
  if (!execution) {
    return [];
  }

  return [
    {
      label: 'Side',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Badge,
        value: execution.side
      }
    },
    {
      label: 'Instrument',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: 'NVDA-US' // replace with instrument when work on TKT-12224
      }
    },
    {
      label: 'Account',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: execution.tradeContraAccountName
      }
    },
    {
      label: 'Quantity',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: execution.executedQuantity,
        format: 'quantity'
      }
    },
    {
      label: 'Price',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: execution.executedPrice,
        format: 'price'
      }
    },
    {
      label: 'Trade Status',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Badge,
        value: execution.status
      }
    },
    {
      label: 'Trade Counterparty',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: execution.tradeCounterParty
      }
    },
    {
      label: 'Trade datetime',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: format('datetime', execution.executionDateTime)
      }
    }
  ];
};

const gridProps: AppDisplayGridProps['gridProps'] = {
  columns: 3,
  columnGap: 10,
  rowGap: 3
};

export const ExecutionViewInformationWidget = ({ data: execution }: { data: ExecutionRow }) => {
  const displayGridItems = useMemo(() => {
    return getDisplayGridItems(execution);
  }, [execution]);

  return (
    <Box sx={{ ...sx, padding: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 5 }}>
        <Text sx={{ color: 'white', marginBottom: 3, display: 'block' }}>Execution Information</Text>
      </Box>
      <AppDisplayGrid items={displayGridItems} labelSize="small" gridProps={gridProps} />
    </Box>
  );
};

export default ExecutionViewInformationWidget;
