import { WindowDecorationComponent as Window } from '@valstro/workspace-react';
import { Actor, AnyActorSchema, Plugin, isTauri } from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { browserAuthWindowActor } from './auth-window.browser.modal.actor';
import { tauriAuthWindowActor } from './auth-window.tauri.actor';
import { AppWindowWidgetDecoration } from '@app/widgets/widget.window-decoration.component';
import type { PropsWithChildren } from 'react';
import { WebviewWindow } from '@tauri-apps/api/webviewWindow';
import { COMMON_AUTH_WINDOW } from '@app/common/auth/auth.contracts';
import type { CommonAuthWindowActorSchema } from '@app/common/auth/auth.contracts';
import type { AuthClientState } from '@app/common/auth/keycloak.types';
import { DependencyContainer } from 'tsyringe';
import { AuthSignal } from '@app/data-access/memory/auth.signal';
import { createWorkspaceLeaderWindowReady$ } from '@app/common/workspace/workspace.rxjs';
import { AppState } from '@app/data-access/memory/app.stream';
import { filter, map, switchMap } from 'rxjs';

function AuthWindowDecoration({
  children,
  ...decorationProps
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  return (
    <Window.Container {...decorationProps}>
      <Window.ContentContainer>{children}</Window.ContentContainer>
    </Window.Container>
  );
}

export interface AuthWindowPluginOptions {
  forceAuthState?: AuthClientState;
  container: DependencyContainer;
}

/**
 * Manages auth state in a singe process / window
 */
export const authWindowPlugin = ({ forceAuthState, container }: AuthWindowPluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-auth-window-plugin',
    pluginFn: ({ workspace }) => {
      if (forceAuthState) {
        container.resolve(AuthSignal).signal.set(forceAuthState);
        return;
      }

      const leaderWindowReady$ = createWorkspaceLeaderWindowReady$(workspace);
      const appState = container.resolve(AppState);
      const authReady$ = leaderWindowReady$.pipe(
        switchMap(({ rootWindowActor }) =>
          appState.$.pipe(
            filter((state) => state.state === 'Authenticating'),
            map((v) => ({ ...v, rootWindowActor }))
          )
        )
      );

      const options = isTauri()
        ? {
            defaultContext: {
              isDecorated: true,
              transparent: false
            },
            windowDecorationComponent: AuthWindowDecoration,
            forceAuthState,
            container
          }
        : {
            defaultContext: {
              isDecorated: false,
              transparent: true
            },
            windowDecorationComponent: AppWindowWidgetDecoration,
            forceAuthState,
            container
          };

      // Register the auth window actors
      workspace.getActorRegistry().register(browserAuthWindowActor(options));
      workspace.getActorRegistry().register(tauriAuthWindowActor(options));

      async function openAuthWindow(rootWindowActor: Actor<AnyActorSchema>) {
        // Sometimes, when the auth window is redirected to keycloak, is doens't pick up the close event.
        // Therefore, we need to close the window manually (if already open) before opening a new one.
        if (isTauri()) {
          const webview = await WebviewWindow.getByLabel(COMMON_AUTH_WINDOW.ID);
          if (webview) {
            await webview.close();
          }
        }

        await rootWindowActor.spawnChild({
          type: COMMON_AUTH_WINDOW.TYPE,
          id: COMMON_AUTH_WINDOW.ID
        });
      }

      const subscription = authReady$.subscribe(({ rootWindowActor }) => {
        openAuthWindow(rootWindowActor).catch(console.error);
      });

      // Initialize the auth window actor
      workspace
        .addActorHook<CommonAuthWindowActorSchema>(COMMON_AUTH_WINDOW.TYPE)
        .after('start', async () => {
          const authActor = await Actor.get<CommonAuthWindowActorSchema>(COMMON_AUTH_WINDOW.ID);
          authActor.operations.initialize(forceAuthState).catch(console.error);
          workspace.updateMeta({
            authActor
          });
        });

      return function unsubscribe() {
        subscription.unsubscribe();
      };
    }
  });
