import { getEnvVar } from '@oms/shared/util';

export interface UserWithEntitlementsResponse {
  data: {
    userWithEntitlements: {
      firstName: string;
      lastName: string;
      avatar: string;
      entitlements: string[];
      __typename: string;
    };
  };
}

export async function userWithEntitlements(accessToken?: string): Promise<UserWithEntitlementsResponse> {
  if (!accessToken) {
    throw new Error('No access token provided to userWithEntitlements');
  }

  // setup graphql query
  const query = `query GetUserWithEntitlements {
    userWithEntitlements {
      firstName
      lastName
      avatar
      entitlements
      __typename
    }
  }`;

  // setup request options
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({ query })
  };

  const gqlUrl = getGraphqlUrl();

  try {
    const response = await fetch(gqlUrl, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error calling GetUserWithEntitlements: ${response.status}`);
    }

    const data: UserWithEntitlementsResponse = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching GetUserWithEntitlements GraphQL data:', error);
    throw error;
  }
}

function getGraphqlUrl() {
  const host = getEnvVar('NX_NEST_API_HOST');
  if (host) {
    const port = getEnvVar('NX_NEST_API_PORT');
    const portString = port ? `:${port}` : '';
    return `https://${host}${portString}/graphql`;
  } else {
    return `https://${window.location.host}/graphql`;
  }
}
