import type { OrderAuditTrail } from '@app/common/types/orders/orders.types';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import type {
  AuditTrailInfoFragment,
  GetAuditStreamForInvestorOrderQuery,
  GetAuditStreamForInvestorOrderQueryVariables
} from '@oms/generated/frontend';
import { Observable, switchMap, map } from 'rxjs';
import { UsersService } from '@app/data-access/services/reference-data/users/users.service';
import { GetAuditStreamForInvestorOrderDocument } from '@oms/generated/frontend';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class InvestorOrderAuditTrailService {
  constructor(
    @inject(RxApolloClient) protected apolloClient: RxApolloClient,
    @inject(UsersService) protected userService: UsersService
  ) {}

  protected _watchQuery_GetAuditStreamForInvestorOrderQuery$(
    investorOrderId: string,
    pollInterval = 5000
  ): Observable<AuditTrailInfoFragment[]> {
    return this.apolloClient
      .rxWatchQuery<GetAuditStreamForInvestorOrderQuery, GetAuditStreamForInvestorOrderQueryVariables>({
        query: GetAuditStreamForInvestorOrderDocument,
        pollInterval,
        fetchPolicy: 'no-cache',
        variables: {
          id: investorOrderId
        }
      })
      .pipe(
        map(
          (res) =>
            (res.data.getAuditStreamForInvestorOrder as AuditTrailInfoFragment[]) ||
            ([] as AuditTrailInfoFragment[])
        )
      );
  }

  public getAuditTrail$(investorOrderId: string): Observable<OrderAuditTrail[]> {
    return this._watchQuery_GetAuditStreamForInvestorOrderQuery$(investorOrderId).pipe(
      switchMap(async (results) => {
        const sanitized = await Promise.all(
          results.map(async (r): Promise<OrderAuditTrail> => {
            const user = r?.metadata?.userId ? await this.userService.getUser(r.metadata.userId) : undefined;

            return {
              id: `${r.aggregateId}-${r.aggregateVersion}`,
              eventTimestamp: r.timestamp,
              event: r?.friendlyEventName || undefined,
              eventDetails: r?.payload,
              bidPrice: r?.marketdataSnapshot?.bidPrice || undefined,
              askPrice: r?.marketdataSnapshot?.askPrice || undefined,
              lastTradePrice: r?.marketdataSnapshot?.lastTradePrice || undefined,
              cumulativeVolume: r?.marketdataSnapshot?.cumulativeVolume || undefined,
              orderEntryType: r?.metadata?.source || undefined,
              user,
              status: r?.metadata?.orderStatus || undefined,
              rejectReason: r?.metadata?.rejectReason || undefined
            };
          })
        );

        return sanitized;
      })
    );
  }
}
