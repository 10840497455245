import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import clsx from 'clsx';
import {
  CommonWindowActorSchema,
  Actor,
  WindowContext,
  ActorStateType,
  COMMON_PLATFORM_NAME,
  AnyActorSchema,
  isTauri
} from '@valstro/workspace';
import {
  ReactActorComponentProps,
  useActorClassNames,
  useActorContext,
  useActorSchemaMeta,
  useWorkspace
} from '../../../core';
import React from 'react';

export interface IWindowDecorationContext {
  actor: Actor<CommonWindowActorSchema>;
  className: ReturnType<typeof useActorClassNames>;
  context: WindowContext;
  state: ActorStateType;
  isFullscreenOrMaximized: boolean;
  canInteract: boolean;
  isDragging: boolean;
  isTitleEditable?: boolean;
}

export const WindowDecorationContext = createContext<IWindowDecorationContext>(
  {} as IWindowDecorationContext
);

export const useWindowDecoration = () => useContext(WindowDecorationContext);

export function CommonWindowDecoration({
  children,
  ...decorationProps
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  return (
    <WindowDecorationContainer {...decorationProps}>
      <WindowDecorationToolbar>
        <WindowDecorationToolbarTitlebar>
          <WindowDecorationToolbarTitlebarTitle />
        </WindowDecorationToolbarTitlebar>
        <WindowDecorationToolbarActionsContainer>
          <WindowDecorationToolbarActions />
        </WindowDecorationToolbarActionsContainer>
      </WindowDecorationToolbar>
      <WindowDecorationContentContainer>{children}</WindowDecorationContentContainer>
    </WindowDecorationContainer>
  );
}

export type WindowDecorationComponentType = React.ComponentType<
  PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
>;

export function useGetWindowDecorationContext(
  actor: Actor<CommonWindowActorSchema>,
  context: WindowContext,
  state: ActorStateType
) {
  const className = useActorClassNames(actor, state);
  const isFullscreenOrMaximized = context.isFullscreen || context.isMaximized;
  const canInteract =
    context.isResizable && isFullscreenOrMaximized === false && context.isMinimized === false;

  const decorationContext: IWindowDecorationContext = useMemo(() => {
    const ctx: IWindowDecorationContext = {
      actor,
      canInteract,
      className,
      context,
      state,
      isDragging: false,
      isFullscreenOrMaximized,
      isTitleEditable: context.isTitleEditable
    };

    return ctx;
  }, [actor, canInteract, className, context, state, isFullscreenOrMaximized]);

  return decorationContext;
}

export function useGetWindowDecorationComponent(
  actor: Actor<
    CommonWindowActorSchema<React.ComponentType<PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>>>
  >
) {
  const meta = useActorSchemaMeta(actor) || {};
  const { windowDecorationComponent: WindowDecorationComponent } = meta;
  return WindowDecorationComponent;
}

function WindowDecorationContainer({
  children,
  className: divClassName,
  style: divStyle = {},
  ...divProps
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  const { actor, className, context, isFullscreenOrMaximized } = useWindowDecoration();
  const { transparent, isFocused } = context;
  const isPopover = actor.id.includes('popover-window');

  const wrapperStyle = useMemo(() => {
    const styles: React.CSSProperties = {};

    if (transparent) {
      styles.backgroundColor = 'transparent';
    }

    return styles;
  }, [transparent]);

  return (
    <div
      style={{
        ...wrapperStyle,
        ...divStyle
      }}
      className={clsx(
        className.wrapper,
        className.draggableContainer,
        {
          'actor-full': isFullscreenOrMaximized
        },
        divClassName,
        isFocused && className.isFocused
      )}
      {...divProps}
    >
      {isPopover ? <>{children}</> : <div className="window-wrapper">{children}</div>}
    </div>
  );
}

function WindowDecorationToolbar({
  children,
  className: divClassName,
  style: divStyle = {},
  onDoubleClick: divOnDoubleClick,
  ...divProps
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  const { className } = useWindowDecoration();

  return (
    <div className={clsx(className.toolbar, divClassName)} style={divStyle} {...divProps}>
      {children}
    </div>
  );
}

function WindowDecorationToolbarTitlebar({
  children,
  className: divClassName,
  style: divStyle = {},
  onDoubleClick: divOnDoubleClick,
  ...divProps
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  const workspace = useWorkspace();
  const { className, context, actor } = useWindowDecoration();
  const { isMaximizable, isMinimizable, isMaximized } = context;

  const handleTitlebarDoubleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (divOnDoubleClick) {
        divOnDoubleClick(e);
      }

      if (workspace.getPlatformName() !== COMMON_PLATFORM_NAME.BROWSER) {
        return;
      }

      if (!isMaximizable || !isMinimizable) {
        return;
      }

      if (!isTauri()) {
        if (isMaximized) {
          actor.operations.unmaximize({ isUserInteraction: true }).catch(console.error);
          return;
        }

        // Wait for the next tick to maximize
        // Note: This is a workaround for a bug where the maximize operation is not applied immediately in testkit.
        // TODO: Find the root cause and fix it.
        setTimeout(() => {
          actor.operations.maximize({ isUserInteraction: true }).catch(console.error);
        }, 0);
      }
    },
    [isMaximizable, isMinimizable, isMaximized, divOnDoubleClick, actor, workspace]
  );

  return (
    <div
      onDoubleClick={handleTitlebarDoubleClick}
      className={clsx(className.toolbarTitlebar, divClassName)}
      // https://github.com/tauri-apps/tauri/issues/10767
      // @ts-ignore
      style={{ ...divStyle, 'app-region': 'drag' }}
      {...divProps}
    >
      {children}
    </div>
  );
}

function WindowDecorationToolbarTitlebarTitle({
  children,
  className: divClassName,
  onDoubleClick: divOnDoubleClick,
  icon,
  ...divProps
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> & { icon?: ReactElement }) {
  const { className, context, actor, isTitleEditable = true } = useWindowDecoration();
  const { title } = context;
  const [isEditingTitle, setEditingTitle] = useState(false);
  const [editingTitle, setEditingTitleValue] = useState(title || 'Untitled');

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingTitleValue(e.target.value);
  };

  const handleTitleBlur = () => {
    if (editingTitle === '') {
      return;
    }
    setEditingTitle(false);
    actor.operations.setTitle(editingTitle, { isUserInteraction: true }).catch(console.error);
  };

  const handleTitleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && editingTitle) {
      setEditingTitle(false);
      actor.operations.setTitle(editingTitle, { isUserInteraction: true }).catch(console.error);
    }

    if (e.key === 'Escape') {
      setEditingTitleValue(title);
      setEditingTitle(false);
    }
  };

  const handleTitleEditDoubleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (divOnDoubleClick) {
      divOnDoubleClick(e);
    }

    if (!isTitleEditable) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    setEditingTitle(true);
  };

  const titleInputRef = useCallback((el: HTMLInputElement) => {
    if (el) {
      el.focus();
      el.select();
    }
  }, []);

  useEffect(() => {
    if (isEditingTitle === false) {
      setEditingTitleValue(title);
    }
  }, [title, isEditingTitle]);

  return (
    <div className={clsx(className.toolbarTitlebarTitle, divClassName)} {...divProps}>
      {icon && icon}
      {isEditingTitle ? (
        <input
          ref={titleInputRef}
          value={editingTitle}
          onChange={handleTitleChange}
          autoFocus
          onBlur={handleTitleBlur}
          onKeyDown={handleTitleKeydown}
        />
      ) : (
        <span onDoubleClick={handleTitleEditDoubleClick}>{title}</span>
      )}
    </div>
  );
}

function WindowDecorationToolbarActionsContainer({
  children,
  className: divClassName,
  ...divProps
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  const { className } = useWindowDecoration();

  return (
    <div className={clsx(className.toolbarActions, divClassName)} {...divProps}>
      {children}
    </div>
  );
}

export type CommonToolbarActionType = 'pin' | 'minimize' | 'maximize' | 'close';

export type CommonToolbarAction<T extends string = string> = {
  type: T;
  component: JSX.Element;
};

export type CommonToolbarActionsTransformer = (
  currentActions: CommonToolbarAction<CommonToolbarActionType>[]
) => (CommonToolbarAction | JSX.Element)[];

export interface WindowDecorationToolbarActionsProps {
  actionsTransformer?: CommonToolbarActionsTransformer;
}

function WindowDecorationToolbarActions({ actionsTransformer }: WindowDecorationToolbarActionsProps) {
  const workspace = useWorkspace();
  const { className, context, actor } = useWindowDecoration();
  const { isPinnable, isPinned, isMinimizable, isMaximizable, isMaximized, isClosable } = context;

  const isLeaderActor = actor.id === workspace.getLeaderProcessId();

  const commonActions = useMemo(() => {
    const actions: CommonToolbarAction<CommonToolbarActionType>[] = [];

    if (isPinnable) {
      actions.push({
        type: 'pin',
        component: (
          <button
            aria-label="Pin Window"
            key="pin"
            className={className.getTitlebarAction('pin')}
            onClick={() => {
              actor.operations.setPinned(!isPinned, { isUserInteraction: true }).catch(console.error);
            }}
          >
            {isPinned ? (
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.62129 1.13607C9.81656 0.940808 10.1331 0.940809 10.3284 1.13607L11.3891 2.19673L12.8033 3.61094L13.8639 4.6716C14.0592 4.86687 14.0592 5.18345 13.8639 5.37871C13.6687 5.57397 13.3521 5.57397 13.1568 5.37871L12.5038 4.7257L8.86727 9.57443L9.97485 10.682C10.1701 10.8773 10.1701 11.1939 9.97485 11.3891C9.77959 11.5844 9.463 11.5844 9.26774 11.3891L7.85353 9.97491L6.79287 8.91425L3.5225 12.1846C3.32724 12.3799 3.01065 12.3799 2.81539 12.1846C2.62013 11.9894 2.62013 11.6728 2.81539 11.4775L6.08576 8.20714L5.0251 7.14648L3.61089 5.73226C3.41563 5.537 3.41562 5.22042 3.61089 5.02516C3.80615 4.8299 4.12273 4.8299 4.31799 5.02516L5.42557 6.13274L10.2743 2.49619L9.62129 1.84318C9.42603 1.64792 9.42603 1.33133 9.62129 1.13607Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
                <path
                  d="M9.62129 1.13607C9.81656 0.940808 10.1331 0.940809 10.3284 1.13607L11.3891 2.19673L12.8033 3.61094L13.8639 4.6716C14.0592 4.86687 14.0592 5.18345 13.8639 5.37871C13.6687 5.57397 13.3521 5.57397 13.1568 5.37871L12.5038 4.7257L8.86727 9.57443L9.97485 10.682C10.1701 10.8773 10.1701 11.1939 9.97485 11.3891C9.77959 11.5844 9.463 11.5844 9.26774 11.3891L7.85353 9.97491L6.79287 8.91425L3.5225 12.1846C3.32724 12.3799 3.01065 12.3799 2.81539 12.1846C2.62013 11.9894 2.62013 11.6728 2.81539 11.4775L6.08576 8.20714L5.0251 7.14648L3.61089 5.73226C3.41563 5.537 3.41562 5.22042 3.61089 5.02516C3.80615 4.8299 4.12273 4.8299 4.31799 5.02516L5.42557 6.13274L10.2743 2.49619L9.62129 1.84318C9.42603 1.64792 9.42603 1.33133 9.62129 1.13607Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.3285 1.13607C10.1332 0.940809 9.81662 0.940808 9.62136 1.13607C9.42609 1.33133 9.42609 1.64792 9.62136 1.84318L10.2744 2.49619L5.42563 6.13274L4.31805 5.02516C4.12279 4.8299 3.80621 4.8299 3.61095 5.02516C3.41569 5.22042 3.41569 5.537 3.61095 5.73226L5.02516 7.14648L6.08582 8.20714L2.81545 11.4775C2.62019 11.6728 2.62019 11.9894 2.81545 12.1846C3.01072 12.3799 3.3273 12.3799 3.52256 12.1846L6.79293 8.91425L7.85359 9.97491L9.2678 11.3891C9.46306 11.5844 9.77965 11.5844 9.97491 11.3891C10.1702 11.1939 10.1702 10.8773 9.97491 10.682L8.86733 9.57443L12.5039 4.7257L13.1569 5.37871C13.3522 5.57397 13.6687 5.57397 13.864 5.37871C14.0593 5.18345 14.0593 4.86687 13.864 4.6716L12.8033 3.61094L11.3891 2.19673L10.3285 1.13607ZM6.13992 6.84702L10.9887 3.21047L11.7896 4.01142L8.15305 8.86015L6.13992 6.84702Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            )}
          </button>
        )
      });
    }

    if (isMinimizable) {
      actions.push({
        type: 'minimize',
        component: (
          <button
            aria-label="Minimize Window"
            key="minimize"
            className={className.getTitlebarAction('minimize')}
            onClick={() => {
              actor.operations.minimize({ isUserInteraction: true }).catch(console.error);
            }}
          >
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.25 7.5C2.25 7.22386 2.47386 7 2.75 7H12.25C12.5261 7 12.75 7.22386 12.75 7.5C12.75 7.77614 12.5261 8 12.25 8H2.75C2.47386 8 2.25 7.77614 2.25 7.5Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        )
      });
    }

    if (isMaximizable) {
      actions.push({
        type: 'maximize',
        component: (
          <button
            aria-label="Maximize Window"
            key="maximize"
            className={className.getTitlebarAction('maximize')}
            onClick={() => {
              actor.operations.toggleMaximize({ isUserInteraction: true }).catch(console.error);
            }}
          >
            {isMaximized ? (
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1 1H1.5H13.5H14V1.5V13.5V14H13.5H1.5H1V13.5V1.5V1ZM2 2V13H13V2H2Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            )}
          </button>
        )
      });
    }

    if (isClosable !== false && isLeaderActor === false) {
      actions.push({
        type: 'close',
        component: (
          <button
            aria-label="Close Window"
            key="close"
            className={className.getTitlebarAction('close')}
            onClick={() => {
              actor.destroy().catch((e) => {
                console.error(e, 'Failed to close actor');
              });
            }}
          >
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        )
      });
    }

    return actions;
  }, [
    isPinnable,
    isPinned,
    className,
    isMinimizable,
    isMaximized,
    isMaximizable,
    actor,
    isClosable,
    isLeaderActor
  ]);

  const actions = useMemo(() => {
    if (!actionsTransformer) {
      return commonActions.map((action) => action.component);
    }

    return actionsTransformer(commonActions).map((action) =>
      React.isValidElement(action) ? action : (action as CommonToolbarAction).component
    );
  }, [actionsTransformer, commonActions]);

  return <>{actions}</>;
}

function WindowDecorationContentContainer({
  children,
  className: divClassName,
  ...divProps
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  const { className } = useWindowDecoration();

  return (
    <div className={clsx(className.content, divClassName)} {...divProps}>
      {children}
    </div>
  );
}

export const WindowDecorationComponent = {
  Container: WindowDecorationContainer,
  ContentContainer: WindowDecorationContentContainer,
  Toolbar: WindowDecorationToolbar,
  ToolbarActions: WindowDecorationToolbarActions,
  ToolbarActionsContainer: WindowDecorationToolbarActionsContainer,
  ToolbarTitlebar: WindowDecorationToolbarTitlebar,
  ToolbarTitlebarTitle: WindowDecorationToolbarTitlebarTitle
} as const;

export function WindowDecorationProvider<TOverride extends AnyActorSchema = CommonWindowActorSchema>({
  actor,
  children,
  state
}: ReactActorComponentProps<TOverride> & {
  children?: React.ReactNode;
  state: ActorStateType;
}) {
  const context = useActorContext(actor);
  const WindowDecorationComponent = useGetWindowDecorationComponent(actor);
  const decorationContext = useGetWindowDecorationContext(actor, context, state);

  return WindowDecorationComponent ? (
    <WindowDecorationContext.Provider value={decorationContext}>
      <WindowDecorationComponent data-actor-id={actor.id} data-actor-type={actor.type}>
        {children}
      </WindowDecorationComponent>
    </WindowDecorationContext.Provider>
  ) : (
    <>{children}</>
  );
}
