import { Box } from '@oms/shared-frontend/ui-design-system';
import { tabContentInner } from '@app/widgets/common/styles/trading.styles.css';
import { paneStyle as sx } from '@app/widgets/common/styles/trading.styles';
import { ExecutionDetailsSummary } from './execution-view.execution-details.widget';
import { ExecutionRow } from '@oms/generated/frontend';

export const ExecutionViewSidePanelWidget = ({ data: trade }: { data: ExecutionRow }) => {
  return (
    <Box className={tabContentInner} sx={{ ...sx, padding: 5 }}>
      <ExecutionDetailsSummary execution={trade} />
    </Box>
  );
};

export default ExecutionViewSidePanelWidget;
