import {
  useVGrid,
  VGrid,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE
} from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { ExecutionStatus } from '@oms/generated/frontend';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import type { ExecutionRow, TsExecutionsWithFilterSubscription } from '@oms/generated/frontend';
import { TsExecutionsWithFilterDocument } from '@oms/generated/frontend';
import type { TableServerMappedExecutions } from '@oms/generated/frontend';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { useCallback } from 'react';
import { buildExecutionsGridColumnDefs } from './trade-view.executions.columns';
import { useService } from '@oms/frontend-foundation';
import { cancelExecutionsAction } from './actions/cancel.executions.action';
import { applySettlementFXRateAction } from './actions/apply-settlement-fx-rate.action';

export const TradeViewExecutionsGrid = ({ id }: { id: ExecutionRow['id'] }) => {
  const datasourceService = useService(TableServerDatasourceService);

  const builder = useCallback(
    (b: GridBuilder<TableServerMappedExecutions>) =>
      b
        .tableServerColumnLibrary(buildExecutionsGridColumnDefs())
        .rowSelection((c) => c.multiple())
        .sideBar()
        .datasource((d) =>
          d
            .source(
              datasourceService.getSource<ExecutionRow, TsExecutionsWithFilterSubscription>({
                query: TsExecutionsWithFilterDocument,
                getData: (r) => r.tsExecutionsWithFilter,
                filter: {
                  tradeId: {
                    filterType: 'text',
                    type: 'equals',
                    filter: id
                  }
                }
              })
            )
            .rowId((r) => {
              return r.data?.tradeId;
            })
        )
        .injectEvents([GridConfigEventHandler])
        .rowStateRules({
          noExecutedQuantity: (params) => params.data?.status === ExecutionStatus.Inactive
        })
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(cancelExecutionsAction)
                .action(applySettlementFXRateAction)
            )
            .settings((s) => s.fromFile({}))
        ),

    [datasourceService, id]
  );

  const props = useVGrid<TableServerMappedExecutions>('trade-view-executions', builder, [datasourceService]);

  return <VGrid {...props} />;
};

export default TradeViewExecutionsGrid;
