import type { DataAccessState } from '@app/common/data-access/data-access.contracts';
import { DEFAULT_DATA_ACCESS_STATE as initalState } from '@app/common/data-access/data-access.contracts';
import { inject, singleton } from 'tsyringe';
import { ProcessState } from './process-id.subject';
import { testScoped } from '@app/workspace.registry';
import { AbstractSignal } from './signal';
import { tap } from 'rxjs';
import * as Sentry from '@sentry/react';

/**
 * Signal for DataAccessState
 *
 * Used to broadcast the current state of data access (graphql, rest, etc)
 * and to listen for changes to the DataAccessState
 *
 * Used primarily at the framework level to broadcast the current state of data access
 * @see app.stream.ts
 * @see plugin-data-access
 *
 * @usage
 * ```ts
 * const dataAccessSignal = container.resolve(DataAccessSignal);
 * const subscription = dataAccessSignal.$.subscribe((state) => {
 *  console.log('Data access state changed', state);
 * });
 * ```
 *
 * @usage
 * ```ts
 * constructor(@inject(DataAccessSignal) private dataAccessSignal: DataAccessSignal) {
 *  const subscription = this.dataAccessSignal.$.subscribe((state) => {
 *    console.log('Data access state changed', state);
 *  });
 * }
 */
@testScoped
@singleton()
export class DataAccessSignal extends AbstractSignal<DataAccessState> {
  constructor(@inject(ProcessState) processState: ProcessState) {
    super(
      processState,
      'dataAccess',
      {
        initialize$: processState.isLeaderProcess$,
        initializeOnce: false,
        broadcast: false
      },
      initalState
    );
    this.signal.$ = this.signal.$.pipe(
      tap((e) => {
        Sentry.setContext('Data Access State', {
          state: e
        });
      })
    );
  }
}
