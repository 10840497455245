import type { Maybe, Optional, Prettify } from '@oms/shared/util-types';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { ActionDefFactory, ActionContext } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import { openConfirmation } from '@app/generated/sdk';
import type {
  ActionCommandConfig,
  ActionCommandContracts,
  ActionCommandType
} from '@app/actions/commands/command.registry.types';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import type { InvestorOrderWithChargesRow } from '../../investor-order-monitor.contracts';
import { ACCEPT_INVESTOR_ORDER_ACTION_NAME } from './accept-investor-order.action.types';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import { runConfirmationButton } from '@app/actions/util/run-confirmation-button.util';
import { cleanMaybe, Result } from '@oms/shared/util';

// Types --------------------------------------------------------------------- /

export type ActionType = ActionCommandType;
export type AcceptInvestorOrderActionArgs = Prettify<
  Pick<InvestorOrderWithChargesRow, 'id'> & {
    status?: string | InvestorOrderStatus;
  }
>;

// Action --------------------------------------------------------------------- /

export const createAcceptInvestorOrderAction =
  <TData extends AcceptInvestorOrderActionArgs>(type: ActionType): ActionDefFactory<TData> =>
  (builder) =>
    builder
      .name(type === 'configurable' ? ACCEPT_INVESTOR_ORDER_ACTION_NAME : 'accept_investor_order_static')
      .toolbar(
        type === 'configurable'
          ? (builder) =>
              builder
                .location('HorizontalToolbarRight')
                .component('action-button')
                .id('left_view_investor_order_button')
                .props({
                  variant: 'primary',
                  content: t('app.commands.acceptInvestorOrder.button'),
                  isDisabled: true
                })
          : null
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);
        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .customMenu(
        type === 'context-menu'
          ? (m) =>
              m
                .name(t('app.commands.acceptInvestorOrder.contextMenu'))
                .tabName(t('app.common.grids.contextMenuTabs.action'))
                .priority(10)
                .visible(({ rowData }) => isActive(rowData))
                .primary()
          : null
      )
      .lifecycles('change', 'init', 'onSelectionChanged', 'onRowDataUpdated')
      .onChange<ActionCommandConfig<ActionCommandContracts['accept_investor_order']>>(
        acceptInvestorOrderActionOnChange
      );

// Util --------------------------------------------------------------------- /

/**
 * Re-useable function to handle the onChange lifecycle of the accept investor order action
 * - This function will accept the investor order
 *
 * @param ctx - The action event
 * @returns Change function
 */
export async function acceptInvestorOrderActionOnChange<T extends AcceptInvestorOrderActionArgs>(
  ctx: ActionContext<T>
) {
  const { lifecycle, data, notify, container, workspace } = ctx;

  const windowId = getLeaderOrTabId(container);

  // For now we only support accepting one order, but we will suport multiple rows eventually.
  const selectedRows = data.filter(isAcceptableRow).slice(0, 1);

  const id = selectedRows[0]?.id;

  // -------- Handle button state --------

  const isDisabled = !isActive(data);

  notify({ isDisabled });

  if (lifecycle !== 'change' || !id || isDisabled) {
    return;
  }

  // -------- Handle clicking the button --------

  notify({ isLoading: true, rowData: selectedRows });

  await runConfirmationButton({
    ctx,
    confirmationType: 'never',
    onConfirm: async ({ container }) => {
      const orderService = container.resolve(InvestorOrdersService);

      const acceptResult = await orderService.acceptOrder(id);

      return await acceptResult.mapToAsync(
        async ({ data }) => {
          const operationId = cleanMaybe(data?.acceptClientOrder?.operationId);
          return Result.success(operationId);
        },
        async (errors) => {
          const message = errors.map((e) => e.message).join(', ');
          await openConfirmation(workspace, windowId, {
            title: t('app.commands.acceptInvestorOrder.errorTitle'),
            componentProps: {
              message: t('app.commands.acceptInvestorOrder.errorMessage', { message }),
              autoClose: true,
              hideCancelButton: true,
              confirmButtonText: t('app.common.ok')
            }
          });
          console.error(message);
          return Result.failure(new Error(message)) as Result<Optional<string>, Error>;
        }
      );
    },
    dialogConfig: {
      title: t('app.orders.acceptIOConfirmation.title'),
      componentProps: {
        autoClose: true,
        message: t('app.orders.acceptIOConfirmation.message'),
        confirmButtonText: t('app.common.yes')
      }
    }
  });

  notify({ isLoading: false, rowData: selectedRows });
}

/**
 * Re-useable function to determine if the row can be accepted
 *
 * @param rowData - The data from the grid
 * @returns Whether the button should be active
 */
export function isAcceptableRow(rowData: Maybe<Partial<AcceptInvestorOrderActionArgs>>): boolean {
  if (!rowData) return false;
  const { id, status } = rowData;
  return !!id && status === InvestorOrderStatus.Unaccepted;
}

/**
 * Re-useable function to determine if the button should be active
 *
 * @param rowData - The data from the grid
 * @returns Whether the button should be active
 */
export function isActive(rowData?: AcceptInvestorOrderActionArgs[]): boolean {
  if (!rowData) return false;
  if (rowData.length < 1) return false;
  return rowData.some(isAcceptableRow);
}
