import type { Maybe, Optional, Prettify } from '@oms/shared/util-types';
import { cleanMaybe, Result } from '@oms/shared/util';
import type { AllGQLResponseErrors } from '@oms/frontend-foundation';
import {
  CancelInvestorOrdersInput,
  CancelInvestorOrdersMutation,
  InvestorOrderStatus,
  ValstroEntitlements
} from '@oms/generated/frontend';
import type { ActionDefFactory, ActionContext } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import type {
  ActionCommandConfig,
  ActionCommandContracts,
  ActionCommandType
} from '@app/actions/commands/command.registry.types';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { runConfirmationButton } from '@app/actions/util/run-confirmation-button.util';
import { isConfiguredButton } from '@app/actions/util/common.util';
import type { InvestorOrderWithChargesRow } from '../../investor-order-monitor.contracts';
import { CANCEL_INVESTOR_ORDER_ACTION_NAME } from './cancel-investor-order.action.types';

// Types --------------------------------------------------------------------- /

export type ActionType = ActionCommandType;
export type CancelInvestorOrderActionArgs = Prettify<
  Pick<InvestorOrderWithChargesRow, 'id'> & {
    status?: string | InvestorOrderStatus;
  }
>;

// Action --------------------------------------------------------------------- /

export const createCancelInvestorOrderAction =
  <TData extends CancelInvestorOrderActionArgs>(type: ActionType): ActionDefFactory<TData> =>
  (builder) =>
    builder
      .name(type === 'configurable' ? CANCEL_INVESTOR_ORDER_ACTION_NAME : 'cancel_investor_order_static')
      .toolbar(
        type === 'configurable'
          ? (builder) =>
              builder
                .location('HorizontalToolbarRight')
                .component('action-button')
                .id('left_view_investor_order_button')
                .props({
                  variant: 'primary',
                  content: t('app.commands.cancelInvestorOrder.button'),
                  isDisabled: true
                })
          : null
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);
        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .customMenu(
        type === 'context-menu'
          ? (m) =>
              m
                .name(t('app.commands.cancelInvestorOrder.contextMenu'))
                .tabName(t('app.common.grids.contextMenuTabs.action'))
                .priority(90)
                .visible(({ rowData }) => isActive(rowData))
                .primary()
          : null
      )
      .lifecycles('change', 'init', 'onSelectionChanged', 'onRowDataUpdated')
      .onChange<ActionCommandConfig<ActionCommandContracts['cancel_investor_order']>>(
        cancelInvestorOrderActionOnChange
      );

// Util --------------------------------------------------------------------- /

/**
 * Re-useable function to handle the onChange lifecycle of the cancel investor order action
 * - This function will open a dialog to cancel the investor order
 *
 * @param ctx - The action event
 * @returns Change function
 */
export async function cancelInvestorOrderActionOnChange<TData extends CancelInvestorOrderActionArgs>(
  ctx: ActionContext<TData>
) {
  const { lifecycle, data, notify } = ctx;

  const selectedRows = data.filter(isCancelableRow);
  const investorOrderIds = selectedRows.map(({ id }) => id);
  const count = investorOrderIds.length;

  // -------- Handle button state --------

  const isDisabled = !isActive(data);

  notify({ isDisabled });

  if (lifecycle !== 'change' || isDisabled) {
    return;
  }

  // -------- Handle clicking the button --------

  notify({ isLoading: true, rowData: selectedRows });

  const result = await runConfirmationButton({
    ctx,
    confirmationType: isConfiguredButton(ctx) ? 'auto' : 'always',
    onConfirm: async ({ container }) => {
      const orderService = container.resolve(InvestorOrdersService);

      const ordersToCancel: CancelInvestorOrdersInput = {
        investorOrderIds
      };

      if (investorOrderIds.length === 0) {
        return Result.failure(new CancelOrdersError('No orders to cancel'));
      }

      const result = await orderService.cancelOrders(ordersToCancel);

      return await result.mapTo(
        ({ data }) => Result.success(cleanMaybe(data)),
        (errors) =>
          Result.failure(new CancelOrdersError('GQL response errors', errors)) as Result<
            Optional<CancelInvestorOrdersMutation>,
            CancelOrdersError
          >
      );
    },
    dialogConfig: {
      title: t('app.orders.cancelIODialog.title', { count }),
      componentProps: {
        autoClose: true,
        message: t('app.orders.cancelIODialog.message', { count }),
        confirmButtonText: t('app.common.yes'),
        cancelButtonText: t('app.common.no')
      }
    },
    allowRetryOnFailures: true,
    getFeedback: ({ errors }) =>
      errors.map(({ message, name }) => ({
        code: name,
        level: 'Error',
        message
      }))
  });

  notify({ isLoading: false, rowData: selectedRows, isDisabled: result.isSuccess() });
}

type CancelOrdersErrorReason = 'No orders to cancel' | 'GQL response errors';

export class CancelOrdersError extends Error {
  public errors: AllGQLResponseErrors;

  constructor(reason: CancelOrdersErrorReason, errors: AllGQLResponseErrors = []) {
    super(reason);
    this.errors = errors;
    Object.setPrototypeOf(this, CancelOrdersError.prototype);
  }
}

/**
 * Re-useable function to determine if the row can be canceled
 *
 * @param rowData - The data from the grid
 * @returns Whether the button should be active
 */
export function isCancelableRow(rowData?: Maybe<Partial<CancelInvestorOrderActionArgs>>): boolean {
  if (!rowData) return false;
  const { id, status } = rowData;
  return !!id && status === InvestorOrderStatus.Active;
}

/**
 * Re-useable function to determine if the button should be active
 *
 * @param rowData - The data from the grid
 * @returns Whether the button should be active
 */
export function isActive(rowData?: CancelInvestorOrderActionArgs[]): boolean {
  if (!rowData) return false;
  if (rowData.length < 1) return false;
  return rowData.some(isCancelableRow);
}
