import { useMemo } from 'react';
import type { TradeRow } from '@oms/generated/frontend';
import { APP_DISPLAY_FIELD_COMPONENT_TYPE, AppDisplayGrid } from '@oms/frontend-foundation';
import type { AppDisplayGridProps } from '@oms/frontend-foundation';
import { Box, Text, format } from '@oms/shared-frontend/ui-design-system';
import { withUpdatedTrade } from '@app/data-access/hoc/with-updated-trade';
import { paneStyle as sx } from '@app/widgets/common/styles/trading.styles';
import { TradeViewUtilityButtonGroup } from './trade-view.utility-button-group';

const getDisplayGridItems = (trade: TradeRow): AppDisplayGridProps['items'] => {
  return [
    {
      label: 'Side',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Badge,
        value: trade.side
      }
    },
    {
      label: 'Instrument',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: trade.instrumentDisplayCode
      }
    },
    {
      label: 'Account',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: trade.tradeContraAccountName
      }
    },
    {
      label: 'Quantity',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: trade.quantity,
        format: 'quantity'
      }
    },
    {
      label: 'Price',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Numeric,
        value: trade.price,
        format: 'price'
      }
    },
    {
      label: 'Trade Status',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Badge,
        value: trade.status
      }
    },
    {
      label: 'Trade Counterparty',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: trade.tradeCounterParty
      }
    },
    {
      label: 'Trade datetime',
      component: {
        type: APP_DISPLAY_FIELD_COMPONENT_TYPE.Text,
        value: format('datetime', trade.createdTime)
      }
    }
  ];
};

const gridProps: AppDisplayGridProps['gridProps'] = {
  columns: 3,
  columnGap: 10,
  rowGap: 3
};

export const TradeViewTradeInformationWidget = ({ data: trade }: { data: TradeRow }) => {
  const displayGridItems = useMemo(() => {
    if (!trade) {
      return [];
    }

    return getDisplayGridItems(trade);
  }, [trade]);

  return (
    <Box sx={{ ...sx, padding: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 5 }}>
        <Text sx={{ color: 'white', marginBottom: 3, display: 'block' }}>Trade Information</Text>
        <TradeViewUtilityButtonGroup tradeStatus={trade.status} tradeId={trade.id} />
      </Box>
      <AppDisplayGrid items={displayGridItems} labelSize="small" gridProps={gridProps} />
    </Box>
  );
};

export default withUpdatedTrade(TradeViewTradeInformationWidget, { sx });
