import { openConfirmation } from '@app/generated/sdk';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import type { ActionDefFactory } from '@oms/frontend-vgrid';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { ValstroEntitlements } from '@oms/generated/frontend';
import { t } from '@oms/codegen/translations';
import { NotificationSettingsService } from '@app/data-access/services/user/notification-settings.service';
import type { NotificationSettingsTypeValue } from '@app/widgets/user/user-preferences/preferences/notification-settings/notification-settings.contracts';

type OpenNotificationDeleteActionArgs = {
  id?: string;
};

function isVisible(rowData: OpenNotificationDeleteActionArgs[], gridType: NotificationSettingsTypeValue) {
  return rowData.length >= 1 && gridType === 'custom';
}

export const openNotificationDeleteAction: <T extends OpenNotificationDeleteActionArgs>(
  gridType: NotificationSettingsTypeValue
) => ActionDefFactory<T> = (gridType) => (builder) =>
  builder
    .name('delete_notification')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('delete_notification_button')
        .location('HorizontalToolbarRight')
        .props({})
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.UsersManage]);
    })
    .customMenu((m) =>
      m
        .name('Delete')
        .visible(({ rowData }) => isVisible(rowData, gridType))
        .primary()
    )
    .onChange(async (ctx) => {
      const { appContainer, lifecycle, data, workspace } = ctx;

      const isEnabled = isVisible(data, gridType);

      ctx.notify({ isDisabled: !isEnabled });

      switch (lifecycle) {
        case 'init':
        case 'refresh': {
          const config =
            gridType === 'custom'
              ? {
                  isVisible: true,
                  content: 'Delete'
                }
              : {
                  isVisible: false
                };
          ctx.notify(config);
          break;
        }
        case 'change': {
          if (isEnabled) {
            const notificationSettingsService = appContainer.resolve(NotificationSettingsService);
            const selectedRowsId = data.map((row) => row.id!);
            const count = selectedRowsId.length;

            try {
              const [_, api] = await openConfirmation(workspace, workspace.getLeaderProcessId(), {
                title: `Delete notification${count > 1 ? 's' : ''}`,
                componentProps: {
                  autoClose: true,
                  message: `Are you sure you want to delete ${count} custom notification${count > 1 ? 's' : ''}?`,
                  confirmButtonText: t('app.common.yes'),
                  confirmButtonProps: {
                    variant: 'primary',
                    palette: 'standard'
                  },
                  cancelButtonText: t('app.common.no')
                }
              });
              const event = await api.awaitFirstEvent;
              switch (event.type) {
                case DIALOG_EVENT_TYPE.OK: {
                  const result = await notificationSettingsService.deleteCustomSettings(selectedRowsId);
                  ctx.notify({ isLoading: false });

                  if (result.isFailure()) {
                    const msgs = result?.errors.map((e) => e.message).join(', ');
                    openMessageDialog(`Error: ${msgs}`, workspace).catch(console.error);
                    throw new Error(msgs);
                  }
                  break;
                }
              }
            } catch (e) {
              openMessageDialog(`Error: ${String(e)}`, workspace).catch(console.error);
              console.error(e);
            }
          }
        }
        default: {
          break;
        }
      }
    });
