import { InvestorOrderStatus, TradingOrderStatus } from '@oms/generated/frontend';

// TODO: executedQuantity should really just be a number. TEMPORARILY it's string or number.
type StateRulesUtilArgs = { status?: string | null; executedQuantity?: string | number } | undefined | null;

// Determines if the given IO row should have the "Pending" row state.
export function IOPending(args: StateRulesUtilArgs) {
  const { status } = args || {};
  return (
    status === InvestorOrderStatus.CancellationPending ||
    status === InvestorOrderStatus.ModificationPending ||
    status === InvestorOrderStatus.Unaccepted ||
    status === InvestorOrderStatus.ExpiryPending
  );
}

// Determines if the given IO row should have the "No Executed Quantity" row state.
export function IONoExecutedQuantity(args: StateRulesUtilArgs) {
  const { status } = args || {};
  const executedQuantity =
    typeof args?.executedQuantity === 'number' ? args?.executedQuantity : Number(args?.executedQuantity);
  return (
    status === InvestorOrderStatus.Rejected ||
    (executedQuantity === 0 &&
      (status === InvestorOrderStatus.Cancelled ||
        status === InvestorOrderStatus.Expired ||
        status === InvestorOrderStatus.Inactive ||
        status === InvestorOrderStatus.ExecutionCancelled))
  );
}

// Determines if the given IO row should have the "Has Executed Quantity" row state.
export function IOHasExecutedQuantity(args: StateRulesUtilArgs) {
  const { status } = args || {};
  const executedQuantity =
    typeof args?.executedQuantity === 'number' ? args?.executedQuantity : Number(args?.executedQuantity);
  return (
    executedQuantity > 0 &&
    (status === InvestorOrderStatus.Cancelled ||
      status === InvestorOrderStatus.Expired ||
      status === InvestorOrderStatus.Inactive ||
      status === InvestorOrderStatus.FullyFilled ||
      status === InvestorOrderStatus.ExecutionCancelled)
  );
}

// Determines if the given TO row should have the "Pending" row state.
export function TOPending(args: StateRulesUtilArgs) {
  const { status, executedQuantity } = args || {};
  return (
    status === TradingOrderStatus.PendingCancel ||
    status === TradingOrderStatus.ModifyRequested ||
    status === TradingOrderStatus.ModifyPending ||
    status === TradingOrderStatus.ExpiryRequested ||
    status === TradingOrderStatus.ExpiryPending ||
    (status === TradingOrderStatus.RouteRequested && executedQuantity === 0)
  );
}

// Determines if the given TO row should have the "No Executed Quantity" row state.
export function TONoExecutedQuantity(args: StateRulesUtilArgs) {
  const { status } = args || {};
  const executedQuantity =
    typeof args?.executedQuantity === 'number' ? args?.executedQuantity : Number(args?.executedQuantity);
  return (
    executedQuantity === 0 &&
    (status === TradingOrderStatus.Inactive ||
      status === TradingOrderStatus.Expired ||
      status === TradingOrderStatus.Cancelled ||
      status === TradingOrderStatus.TradeCancelled)
  );
}

// Determines if the given TO row should have the "Has Executed Quantity" row state.
export function TOHasExecutedQuantity(args: StateRulesUtilArgs): boolean {
  const { status } = args || {};
  const executedQuantity =
    typeof args?.executedQuantity === 'number' ? args?.executedQuantity : Number(args?.executedQuantity);
  return (
    executedQuantity > 0 &&
    (status === TradingOrderStatus.Inactive ||
      status === TradingOrderStatus.Expired ||
      status === TradingOrderStatus.FullyFilled ||
      status === TradingOrderStatus.Cancelled ||
      status === TradingOrderStatus.TradeCancelled)
  );
}

// Determines if the given TO row should have the "Hazard" row state.
export function TOHazard(args: StateRulesUtilArgs): boolean {
  const { status } = args || {};
  return status === TradingOrderStatus.Rejected;
}
