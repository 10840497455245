import { PlusIcon } from '@radix-ui/react-icons';
import type { ActionDefFactory } from '@oms/frontend-vgrid';
import { ValstroEntitlements } from '@oms/generated/frontend';
import { openCustomNotificationEntry } from '@app/generated/sdk';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type { CustomNotificationEntryType } from '@app/widgets/user/custom-notification-entry/custom-notification-entry.form-common';
import type { NotificationSettingsTypeValue } from '@app/widgets/user/user-preferences/preferences/notification-settings/notification-settings.contracts';

type OpenNotificationEntryActionArgs = {
  id?: string;
};

const isModifyEnabled = (
  mode: CustomNotificationEntryType,
  gridType: NotificationSettingsTypeValue,
  rowData: OpenNotificationEntryActionArgs[]
): boolean => mode === 'update' && gridType === 'custom' && rowData !== undefined;

export const openNotificationEntryAction: <T extends OpenNotificationEntryActionArgs>(
  mode: CustomNotificationEntryType,
  gridType: NotificationSettingsTypeValue
) => ActionDefFactory<T> = (mode, gridType) => (builder) =>
  builder
    .name(`notification_${mode}`)
    .toolbar((t) =>
      t
        .component('action-button')
        .id(`notification_${mode}_button`)
        .location(mode === 'create' ? 'HorizontalToolbarLeft' : 'HorizontalToolbarRight')
    )
    .customMenu((m) =>
      m
        .name('Edit')
        .visible(({ rowData }) => isModifyEnabled(mode, gridType, rowData))
        .primary()
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.UsersManage]);
    })
    .onChange(async (ctx) => {
      const { data, lifecycle, workspace } = ctx;

      const selectedRow = data?.[0];
      const isOnlyOneRowSelected = data?.length === 1;
      const isModifiable =
        mode === 'create' || (isOnlyOneRowSelected && isModifyEnabled(mode, gridType, [selectedRow]));

      ctx.notify({ isDisabled: !isModifiable });

      switch (lifecycle) {
        case 'init':
        case 'refresh': {
          const config =
            gridType === 'custom'
              ? {
                  isVisible: true,
                  variant: mode === 'create' ? 'link' : 'primary',
                  leftIcon: mode === 'create' ? <PlusIcon /> : undefined,
                  content: mode === 'create' ? undefined : 'Edit'
                }
              : {
                  isVisible: false
                };
          ctx.notify(config);
          break;
        }
        case 'change': {
          await openCustomNotificationEntry(workspace.getLeaderProcessId(), {
            title: mode === 'update' ? 'Edit custom notification' : 'New custom notification',
            form: {
              input: {
                entryType: mode,
                notificationId: mode === 'update' ? selectedRow.id : undefined
              }
            }
          });
          break;
        }
        default: {
          break;
        }
      }
    });
