import { FormContract, FieldDefinition } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import {
  email,
  message,
  errorMessage,
  screenshot,
  name,
  event_id,
  captureContext
} from './bug-reporter.form-fields';
import type { BugReporterFormOutput } from './bug-reporter.form-common';

export const bugReporterFormContract = FormContract.create<BugReporterFormOutput>()
  .fields({
    name,
    email,
    message,
    errorMessage,
    screenshot,
    event_id,
    captureContext
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box('wrapper', [f.name, f.email, f.message, f.errorMessage, f.screenshot], {
        sx: {}
      })
    ]
  }))
  .template('simple', { submitLabel: 'Submit', formFieldsSx: { px: 4, pb: 2, marginTop: 4 } });

export type BugReporterFormContractType = typeof bugReporterFormContract;

export type BugReporterFormValues = InferFormValuesFromFormContract<BugReporterFormContractType>;
