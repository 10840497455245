import { TabContent, TabTrigger, Tabs, TabsList } from '@oms/shared-frontend/ui-design-system';
import { paneStyle as sx } from '@app/widgets/common/styles/trading.styles';

export type TabsComponentProps = {
  defaultValue: string;
  tabs: { name: string; value: string; tabContent: React.ReactNode }[];
};

export const ViewTabsList = ({ defaultValue, tabs }: TabsComponentProps) => {
  return (
    <Tabs
      defaultValue={defaultValue}
      sx={{ ...sx, padding: 0, position: 'absolute', inset: 0, display: 'flex', flexDirection: 'column' }}
    >
      <TabsList style={{ padding: 0, marginTop: 1, marginLeft: 15, marginBottom: 0 }}>
        {tabs.map((tab) => (
          <TabTrigger key={tab.value} value={tab.value}>
            {tab.name}
          </TabTrigger>
        ))}
      </TabsList>

      {tabs.map((tab) => (
        <TabContent
          key={tab.value}
          value={tab.value}
          sx={{
            height: 'full',
            flexDirection: 'column',
            borderBottomStyle: 'solid',
            borderBottomWidth: '4px',
            borderColor: 'layout.level1'
          }}
        >
          {tab.tabContent}
        </TabContent>
      ))}
    </Tabs>
  );
};

export default ViewTabsList;
