import { useClosestAppFlexLayoutActorProps } from '@app/common/workspace/workspace.hooks';
import type { ExecutionViewLayoutComponentProps } from './execution-view.layout.config';
import ViewTabsList from '@app/widgets/common/components/view.tabs-list';

const getTabsListProps = () => [
  { name: 'Trade', value: 'trade', tabContent: <div>Trade...</div> },
  { name: 'Audit trail', value: 'auditTrail', tabContent: <div>Audit trail...</div> }
];

export const TradeViewTabbedGridsWidget = () => {
  const [props] = useClosestAppFlexLayoutActorProps<ExecutionViewLayoutComponentProps>();

  if (!props) {
    throw new Error('TradeViewTabbedGridsWidget requires a trading order ID');
  }
  return <ViewTabsList defaultValue="trade" tabs={getTabsListProps()} />;
};

export default TradeViewTabbedGridsWidget;
