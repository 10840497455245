import { TradesService } from '@app/data-access/services/trading/trades/trades.service';
import { openConfirmation } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import { TradeStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { CancelTradesInput } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { convertAlert, useWorkspaceContainer } from '@oms/frontend-foundation';
import type { FoundationWorkspace } from '@oms/frontend-foundation';
import type { FeedbackWrapper } from '@oms/frontend-foundation';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { useCallback } from 'react';
import { useAppWorkspace } from '@app/common/workspace/workspace.hooks';
import { useCurrentWindow } from '@valstro/workspace-react';
import type { DependencyContainer } from 'tsyringe';

type CancelTradeActionActionArgs = {
  id: string;
  status: string;
};

export interface IOpenCancelTradeDialogOptions {
  workspace: FoundationWorkspace;
  container: DependencyContainer;
  windowId: string;
  tradeIds: string[];
}
/**
 * Open a Confirmation Dialog with the relevant errors to display, handle the "OK" button
 * click to send the CancelTrades mutation, and handle any failures by recursion.
 */
async function openConfirmationAndListen(
  tradeIds: string[],
  errors: FeedbackWrapper[],
  tradeService: TradesService,
  workspace: FoundationWorkspace,
  numRows: number
) {
  // Convert the FeedbackWrapper[] into AlertBannerStackItem[]
  const alerts = errors.map((item) =>
    convertAlert.formValidationAlertItem.item(item).toAlertBannerStackItem()
  );

  // Invoke the confirmation dialog with the relevant info.
  const [_, api] = await openConfirmation(workspace, PROCESS_ID.LEADER, {
    title: `Cancel ${numRows} trade${numRows > 1 ? 's' : ''}`,
    componentProps: {
      autoClose: true,
      alerts: alerts,
      message: `Are you sure you want to cancel ${numRows} trade${numRows > 1 ? 's' : ''}?`,
      confirmButtonText: t('app.common.retry'),
      cancelButtonText: t('app.common.no')
    }
  });

  const event = await api.awaitFirstEvent;
  switch (event.type) {
    case DIALOG_EVENT_TYPE.OK: {
      const input: CancelTradesInput = {
        ids: tradeIds
      };

      const resp = await tradeService.cancelTrades(input);

      if (resp.isSuccess() === false) {
        // The mutation failed. Invoke another confirmation.
        const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];
        await openConfirmationAndListen(tradeIds, feedbackWrappers, tradeService, workspace, numRows);
      }

      break;
    }
  }
}

function getActiveRowCount(data: CancelTradeActionActionArgs[]) {
  return data.filter((selectedRow) => selectedRow.status === TradeStatus.Active).length;
}

function isVisible(rowData: CancelTradeActionActionArgs[]): boolean {
  return getActiveRowCount(rowData) > 0;
}

export const cancelTradeOnChange = async <T extends CancelTradeActionActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data, workspace, windowActor } = ctx;

  const activeRowCount = getActiveRowCount(data);

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    if (activeRowCount === 0) {
      return;
    }

    const tradesService = ctx.appContainer.resolve(TradesService);

    const [_, api] = await openConfirmation(workspace, windowActor.id, {
      title: `Cancel ${activeRowCount} trade${activeRowCount > 1 ? 's' : ''}`,
      componentProps: {
        autoClose: true,
        message: `Are you sure you want to cancel ${activeRowCount} trade${activeRowCount > 1 ? 's' : ''}?`,
        confirmButtonText: t('app.common.yes'),
        cancelButtonText: t('app.common.no')
      }
    });
    const event = await api.awaitFirstEvent;
    switch (event.type) {
      case DIALOG_EVENT_TYPE.OK: {
        // By the time the user clicks past the confirmation dialog, the count of selected rows
        // that are active may have changed.
        const activeTradeIds = data
          .filter((selectedRow) => selectedRow.status === TradeStatus.Active)
          .map((row) => row.id);

        const input: CancelTradesInput = {
          ids: activeTradeIds
        };

        const resp = await tradesService.cancelTrades(input);

        if (resp.isSuccess() === false) {
          const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];

          await openConfirmationAndListen(
            activeTradeIds,
            feedbackWrappers,
            tradesService,
            workspace,
            activeTradeIds.length
          );
        }

        break;
      }
    }
  }
};

export const cancelTradeAction =
  <T extends CancelTradeActionActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('cancel_trade')
      .toolbar((t) =>
        t.component('action-button').id('cancel_trade_button').location('HorizontalToolbarRight').props({
          isDisabled: true,
          content: 'Cancel'
        })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.ForceCancel]);
      })
      .customMenu((m) =>
        m
          .name('Cancel')
          .visible(({ rowData }) => isVisible(rowData))
          .tabName(t('app.common.grids.contextMenuTabs.action'))
      )
      .onChange<ActionComponentConfig<T>>(cancelTradeOnChange);
export const openCancelTradeDialog = async ({
  workspace,
  container,
  windowId,
  tradeIds
}: IOpenCancelTradeDialogOptions) => {
  const tradesService = container.resolve(TradesService);
  const rowsCount = tradeIds.length;

  const [_, api] = await openConfirmation(workspace, windowId, {
    title: `Cancel ${rowsCount} trade${rowsCount > 1 ? 's' : ''}`,
    componentProps: {
      autoClose: true,
      message: `Are you sure you want to cancel ${rowsCount} trade${rowsCount > 1 ? 's' : ''}?`,
      confirmButtonText: t('app.common.yes'),
      cancelButtonText: t('app.common.no')
    }
  });
  const event = await api.awaitFirstEvent;
  switch (event.type) {
    case DIALOG_EVENT_TYPE.OK: {
      const input: CancelTradesInput = {
        ids: tradeIds
      };

      const resp = await tradesService.cancelTrades(input);

      if (resp.isSuccess() === false) {
        const feedbackWrappers: FeedbackWrapper[] = resp.errors as unknown as FeedbackWrapper[];

        await openConfirmationAndListen(
          tradeIds,
          feedbackWrappers,
          tradesService,
          workspace,
          tradeIds.length
        );
      }

      break;
    }
  }
};

export const useOpenCancelTradeDialog = (id: string) => {
  const workspace = useAppWorkspace();
  const container = useWorkspaceContainer();
  const windowActor = useCurrentWindow();

  return useCallback(() => {
    openCancelTradeDialog({ workspace, container, windowId: windowActor.id, tradeIds: [id] }).catch((e) =>
      console.error(e)
    );
  }, [id, windowActor.id, workspace, container]);
};
