import { Actor, isTauri, Plugin } from '@valstro/workspace';
import * as Sentry from '@sentry/react';
import { getAppEnv, getEnvVar } from '@oms/shared/util';
import { useEffect, useRef } from 'react';
import { useCurrentAppWidget, useCurrentAppWindow } from '@app/common/workspace/workspace.hooks';
import { AppWindowActorSchema, AppWorkspace } from '@app/app-config/workspace.config';
import { RemoteFormComponentProps } from '@app/widgets/system/remote-form/remote-form.widget.config';
import { globalSentrySignal } from '@app/data-access/memory/sentry.signal';

export const sentryWidgetContext = 'Widget';
export const sentryWidgetSchemaVersion = 'widget_schema_version';
export const sentryWidgetValidSchema = 'widget_valid_schema';
export const sentryWidgetComponent = 'widget_component';
export const sentryWidgetProps = 'widget_props';
export const sentryWidgetRoles = 'widget_roles';
export const sentryWidgetTitle = 'widget_title';

// window context
export const sentryWidgetWindowId = 'widget_window_id';
export const sentryWidgetWindowParentId = 'widget_window_parent_id';
export const sentryWidgetWindowLeader = 'widget_window_leader';
export const sentryWidgetWindowType = 'widget_window_type';
export const sentryWidgetWindowActive = 'widget_window_active';
export const sentryWidgetType = 'widget_type';

/**
 * Should telemetry be enabled
 */
const enableTelemetry = getEnvVar('NX_UI_ENABLE_TELEMETRY') === 'true';

/**
 * Initializes Sentry
 */
export const createSentryPlugin = (disable: boolean = false) => {
  const appEnv = getAppEnv()?.format({ case: 'lower' });
  const appVersion = getEnvVar('NX_RELEASE_VERSION');
  const sentryUrl = getEnvVar('SENTRY_DSN');
  const enableSentry = getEnvVar('NX_UI_ENABLE_SENTRY', 'false') === 'true';

  Sentry.registerSpanErrorInstrumentation();

  Sentry.init({
    enabled: !!appVersion && enableSentry && !disable,
    debug: false,
    dsn: sentryUrl,
    integrations: [Sentry.browserProfilingIntegration()],
    tracePropagationTargets: [/^\/graphql/, /localhost/],
    tracesSampleRate: enableTelemetry ? 0.2 : 0,
    profilesSampleRate: 0.2,
    environment: appEnv,
    release: appVersion,
    normalizeDepth: 20,
    beforeSend(event) {
      if (event.exception && event.event_id) {
        globalSentrySignal.set({
          lastException: {
            eventId: event.event_id,
            message: event.exception.values?.[0]?.value ?? '',
            timestamp: event.timestamp ?? 0
          }
        });
      }
      return event;
    }
  });

  Sentry.setTags({
    platform: isTauri() ? 'desktop' : 'browser'
  });

  return sentryPlugin;
};

const sentryPlugin = () => {
  const windowReadyTimeout = 60_000;
  const windowReady = Sentry.startInactiveSpan({
    name: 'app.window.ready',
    op: 'app.window'
  });

  const timeout = setTimeout(() => {
    if (windowReady.isRecording()) {
      windowReady.setStatus({ code: 2, message: 'deadline_exceeded' });
      windowReady.end();
    }
  }, windowReadyTimeout);

  return Plugin.create<AppWorkspace>({
    name: 'valstro-sentry-plugin',
    pluginFn: ({ workspace }) => {
      const unsub = workspace.addHook('windowReady', ({ rootWindowActor, isLeader = false }) => {
        windowReady.setAttributes({
          'app.window.widget': (rootWindowActor as Actor<AppWindowActorSchema>).initialDefinition.context.meta
            .widgetType,
          'app.window.is_leader': isLeader
        });
        windowReady.setStatus({ code: 1 });
        windowReady.end();
      });

      return () => {
        unsub();
        clearTimeout(timeout);
      };
    }
  });
};

export const useSentryWidgetContext = () => {
  const metaRef = useRef<Record<string, any>>({});
  const widgetContextName = useRef<string | undefined>(undefined);
  const widget = useCurrentAppWidget();
  const appWindow = useCurrentAppWindow();

  useEffect(
    function updateSentryMetaForWindow() {
      const unsub = appWindow.listen('context', ({ title, meta, isFocused = false }) => {
        const { requiredEntitlements = [], windowType, widgetType } = meta;

        metaRef.current[sentryWidgetTitle] = title;
        metaRef.current[sentryWidgetRoles] = requiredEntitlements;
        metaRef.current[sentryWidgetWindowType] = windowType;
        metaRef.current[sentryWidgetType] = widgetType;
        metaRef.current[sentryWidgetWindowLeader] = appWindow.id === appWindow.parentId;
        metaRef.current[sentryWidgetWindowId] = appWindow.id;
        metaRef.current[sentryWidgetWindowParentId] = appWindow.parentId;
        metaRef.current[sentryWidgetWindowActive] = !!isFocused;

        widgetContextName.current && Sentry.setContext(widgetContextName.current, metaRef.current);
      });

      return () => {
        widgetContextName.current && Sentry.setContext(widgetContextName.current, null);
        unsub();
      };
    },
    [appWindow]
  );

  useEffect(
    function updateSentryMetaForWidget() {
      const unsub = widget.listen(
        'context',
        ({ componentId: componentIdEvent, componentProps, meta = {}, version }) => {
          let componentId = componentIdEvent;
          if (componentId === 'SYSTEM_REMOTE_FORM') {
            componentId = (componentProps as unknown as RemoteFormComponentProps).formBuilderId;
          }

          const { subSchemaInvalid } = meta;

          const previousContextName = widgetContextName.current;
          widgetContextName.current = `${sentryWidgetContext} ${widget.id}`;

          if (!widgetContextName.current && previousContextName) {
            Sentry.setContext(previousContextName, null);
          }

          if (widgetContextName.current) {
            metaRef.current[sentryWidgetComponent] = componentId;
            metaRef.current[sentryWidgetProps] = componentProps;
            metaRef.current[sentryWidgetSchemaVersion] = version;
            metaRef.current[sentryWidgetValidSchema] = !subSchemaInvalid;

            Sentry.setContext(widgetContextName.current, metaRef.current);
          }
        },
        { emitInitial: true }
      );

      return () => {
        widgetContextName.current && Sentry.setContext(widgetContextName.current, null);
        unsub();
      };
    },
    [widget]
  );
};
