import ViewTabsList from '@app/widgets/common/components/view.tabs-list';
import { useClosestAppFlexLayoutActorProps } from '@app/common/workspace/workspace.hooks';
import type { ExecutionViewLayoutComponentProps } from './execution-view.layout.config';
import { UpdatedInvestorOrder } from '@app/data-access/hoc/with-updated-investor-order';

const getTabsListProps = () => [
  {
    name: 'Investor Order',
    value: 'investorOrder',
    tabContent: <div> Investor order... </div>
  },
  { name: 'Market charges', value: 'marketCharges', tabContent: <div>Market charges...</div> }
];

export const ExecutionViewTabbedDetailsWidget = ({ data: _investorOrder }: UpdatedInvestorOrder) => {
  const [props] = useClosestAppFlexLayoutActorProps<ExecutionViewLayoutComponentProps>();

  if (!props) {
    throw new Error('ExecutionViewTabbedDetailsWidget requires a trading order ID');
  }
  return <ViewTabsList defaultValue="investorOrder" tabs={getTabsListProps()} />;
};

export default ExecutionViewTabbedDetailsWidget;
