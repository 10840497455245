import React, { useCallback, useState } from 'react';
import type { FieldProps, ICommonTextField } from '../../types';
import { type FORM_COMPONENT_TYPE } from '../../contracts';
import { type Validator } from '@data-driven-forms/react-form-renderer';
import { useFieldApi, useFieldFocus } from '../../helpers';
import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { Flex, Input, Button, CloseButton, Spinner } from '@oms/shared-frontend/ui-design-system';
import { isTauri } from '@valstro/workspace';
import { useCurrentWindow } from '@valstro/workspace-react';

export interface IFileField<TValidator = Validator>
  extends ICommonTextField<typeof FORM_COMPONENT_TYPE.FILE_FIELD, File, TValidator> {
  allowScreenshot?: boolean;
  shouldCompressImage?: boolean;
}

export const FileField: React.FC<FieldProps<IFileField>> = (props) => {
  const {
    meta,
    label,
    input: { name, onBlur, onChange, value },
    isVisible,
    helperText,
    isRequired,
    isDisabled: _isDisabled,
    forceIsDisabled,
    isReadOnly,
    isInvalid,
    isFeatureField,
    isPrimaryField,
    requiredFieldIndicatorStyle,
    hideFormControls,
    style,
    sx,
    wrapperSx = {},
    format,
    initialValue: _initialValue,
    value: _value,
    allowScreenshot = false,
    shouldCompressImage = true,
    fieldDef: _fieldDef,
    ...rest
  } = useFieldApi<IFileField, File>(props);
  const currentWindow = useCurrentWindow();
  const [isCompressing, setIsCompressing] = useState(false);

  const isDisabled = !!forceIsDisabled || !!_isDisabled;
  const hidden = isVisible === false;
  const [inputRef, onInputFocus] = useFieldFocus<HTMLInputElement>(props);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const file = event.target.files[0];
        if (file.type.startsWith('image/') && shouldCompressImage) {
          setIsCompressing(true);
          compressImage(file)
            .then((compressedFile) => {
              onChange(compressedFile);
              setIsCompressing(false);
            })
            .catch((err) => {
              console.error('Error compressing image:', err);
              setIsCompressing(false);
            });
        } else {
          onChange(file);
        }
      }
    },
    [currentWindow]
  );

  const fileName = value?.name;

  const captureScreenshot = useCallback(() => {
    let currentWindowWidth: number | undefined;
    let currentWindowHeight: number | undefined;

    const prepareTauriWindow = async () => {
      if (isTauri()) {
        const { width, height } = await currentWindow.context();
        currentWindowWidth = width;
        currentWindowHeight = height;
        document.body.classList.add('hidden');
        await Promise.all([
          currentWindow.operations.setSize({
            width: 1600,
            height: 1200
          }),
          currentWindow.operations.setAlwaysOnTop(true)
        ]);
      }
    };

    const resetTauriWindow = () => {
      if (isTauri() && currentWindowWidth && currentWindowHeight) {
        document.body.classList.remove('hidden');
        void Promise.all([
          currentWindow.operations.setSize({
            width: currentWindowWidth,
            height: currentWindowHeight
          }),
          currentWindow.operations.setAlwaysOnTop(false)
        ]).catch(console.error);
      }
    };

    void (async () => {
      try {
        await prepareTauriWindow();

        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            displaySurface: 'monitor'
          }
        });

        // Add delay to allow "Choose monitor" dialog to close
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Get the video track settings to determine what was captured
        const videoTrack = stream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();

        // Generate appropriate filename based on display surface
        const getScreenshotName = () => {
          const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
          const surface = settings.displaySurface;

          switch (surface) {
            case 'monitor':
              return `monitor-${timestamp}.png`;
            case 'window':
              return `window-${timestamp}.png`;
            case 'browser':
              return `browser-${timestamp}.png`;
            default:
              return `screenshot-${timestamp}.png`;
          }
        };

        const video = document.createElement('video');
        video.srcObject = stream;

        await new Promise((resolve) => {
          video.onloadedmetadata = () => {
            video.play().catch(console.error);
            resolve(null);
          };
        });

        resetTauriWindow();

        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(video, 0, 0);

        // Stop all tracks
        stream.getTracks().forEach((track) => track.stop());

        // Convert to file with dynamic name
        canvas.toBlob((blob) => {
          if (blob) {
            const fileName = getScreenshotName();
            const file = new File([blob], fileName, { type: 'image/png' });
            void compressImage(file).then((compressedFile) => {
              onChange(compressedFile);
            });
          }
        }, 'image/png');
      } catch (err) {
        console.warn('Cancelled or error capturing screenshot:', err);
        resetTauriWindow();
      }
    })();
  }, [currentWindow]);

  const handleUploadClick = useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);

  return (
    <FieldWrapper
      meta={meta}
      label={label}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      requiredFieldIndicatorStyle={requiredFieldIndicatorStyle}
      helperText={helperText}
      hideFormControls={hideFormControls}
      isFeatureField={isFeatureField}
      isPrimaryField={isPrimaryField}
      isVisible={isVisible}
      sx={wrapperSx}
    >
      <Flex sx={{ position: 'relative', gap: 2 }}>
        <Flex sx={{ position: 'relative', flex: 1 }} style={{ minWidth: 0 }}>
          <Input
            ref={inputRef}
            onBlur={onBlur}
            onChange={handleChange}
            onFocus={onInputFocus}
            hidden={hidden}
            style={{
              ...style,
              opacity: 0,
              position: 'absolute',
              width: '100%',
              cursor: 'pointer',
              height: 0,
              padding: 0
            }}
            sx={sx}
            format={format}
            {...rest}
            name={name}
            type="file"
            accept="image/*"
            multiple={false}
          />
          <Flex
            align="center"
            sx={{
              borderStyle: 'dashed',
              borderWidth: '1px',
              borderColor: fileName ? 'border.active' : 'border.minimal',
              borderRadius: 'md',
              pl: 2,
              pr: 5,
              width: 'full',
              position: 'relative'
            }}
            style={{
              minWidth: 0
            }}
          >
            <Flex
              style={{
                flex: '1 1 auto',
                minWidth: 0
              }}
            >
              <span
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: '100%'
                }}
              >
                {fileName || 'Choose a file'}
              </span>
            </Flex>
            {isCompressing && <Spinner fillArea />}
            {fileName && (
              <CloseButton
                onClick={() => onChange(null)}
                variant="link"
                style={{
                  position: 'absolute',
                  right: 4
                }}
              />
            )}
          </Flex>
        </Flex>
        <Button onClick={handleUploadClick} disabled={isDisabled} type="button" variant="secondary">
          Upload
        </Button>
        {allowScreenshot && (
          <Button disabled={isDisabled} type="button" variant="secondary" onClick={captureScreenshot}>
            Screenshot
          </Button>
        )}
      </Flex>
    </FieldWrapper>
  );
};

/**
 * Compresses an image file while maintaining quality
 *
 * @param file The image file to compress
 * @param maxWidth Maximum width of the compressed image
 * @returns Promise<File> A compressed image file
 */
export async function compressImage(file: File, maxWidth = 1280): Promise<File> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // Calculate new dimensions while maintaining aspect ratio
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        // Convert to blob with reduced quality
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const newFile = new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now()
              });
              resolve(newFile);
            } else {
              resolve(file); // Fallback to original file if compression fails
            }
          },
          'image/jpeg',
          0.7 // Compression quality (0.7 = 70% quality)
        );
      };
    };
  });
}
