import type {
  InferRegistryFormVersionedSchema,
  InferRegistryFormValuesVersionedSchema
} from '@app/app-config/registry.config';
import { registerForm, COMMON_OBJECT_CATEGORY } from '@app/app-config/registry.config';
import { ValstroEntitlements } from '@oms/generated/frontend';
import { LATEST_VERSIONED_SCHEMA } from './schema.route-order.form-builder.input';
import { LATEST_VERSIONED_SCHEMA as LATEST_FORM_VALUES_SCHEMA } from './schema.route-order.form-builder.values';

export const defaultWindowOptions = {
  width: 800,
  height: 404,
  isPinnable: true,
  isMaximizable: false,
  minWidth: 500,
  minHeight: 280
} as const;

const config = registerForm({
  key: 'ROUTE_ORDER',
  title: 'Trading Order Entry',
  objectCategory: COMMON_OBJECT_CATEGORY.TRADING,
  versionedSchema: LATEST_VERSIONED_SCHEMA,
  versionedFormValuesSchema: LATEST_FORM_VALUES_SCHEMA,
  form: {
    input: {}
  },
  showInCommandPalette: true,
  hasEntitlement: [ValstroEntitlements.OrderManage],
  isUnique: true,
  windowOptions: defaultWindowOptions
});

export default config;

export type RouteOrderFormInputSchema = InferRegistryFormVersionedSchema<typeof config>;
export type RouteOrderFormValuesSchema = InferRegistryFormValuesVersionedSchema<typeof config>;
