import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import type { BugReporterFormOutput } from './bug-reporter.form-common';
import { FieldContract } from '@oms/frontend-foundation';

const fc = FieldContract.create<BugReporterFormOutput, AdditionalFormFieldUnion>();

export const name = fc.field('name', 'text-field').options({
  label: 'Name',
  isRequired: true,
  validate: [{ type: 'required' }]
});

export const email = fc.field('email', 'text-field').options({
  label: 'Email',
  isRequired: true,
  validate: [{ type: 'required' }, { type: 'email' }]
});

export const message = fc.field('message', 'textarea').options({
  label: 'Message',
  isRequired: false,
  rows: 3
});

export const errorMessage = fc.field('errorMessage', 'text-field').options({
  label: 'Last known error',
  forceIsDisabled: true
});

export const event_id = fc.field('event_id', 'hidden-field').options({});
export const captureContext = fc.field('captureContext', 'hidden-field').options({});

export const screenshot = fc.field('screenshot', 'file-field').options({
  label: 'Screenshot',
  allowScreenshot: true,
  isRequired: false
});
