import type { Prettify } from '@oms/shared/util-types';
import { ValstroEntitlements } from '@oms/generated/frontend';
import type { ActionDefFactory, ActionContext } from '@oms/frontend-vgrid';
import { t } from '@oms/codegen/translations';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type {
  ActionCommandConfig,
  ActionCommandContracts,
  ActionCommandType
} from '@app/actions/commands/command.registry.types';
import type { InvestorOrderWithChargesRow } from '@app/widgets/trading/investor-order-monitor/investor-order-monitor.contracts';
import { openViewInvestorOrder } from '@app/common/types/orders/investor-order/open.view.investor-order';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { VIEW_INVESTOR_ORDER_ACTION_NAME } from './view-investor-order.action.types';

// Types --------------------------------------------------------------------- /

export type ActionType = ActionCommandType;
export type ViewInvestorOrderActionArgs = Prettify<
  Partial<
    Pick<
      InvestorOrderWithChargesRow,
      'id' | 'instrumentDisplayCode' | 'limitPrice' | 'orderType' | 'side' | 'totalQuantity'
    >
  >
>;

// Action --------------------------------------------------------------------- /

export const createViewInvestorOrderAction =
  <TData extends ViewInvestorOrderActionArgs>(type: ActionType): ActionDefFactory<TData> =>
  (builder) =>
    builder
      .name(type === 'configurable' ? VIEW_INVESTOR_ORDER_ACTION_NAME : 'view_investor_order_static')
      .toolbar(
        type === 'configurable'
          ? (builder) =>
              builder
                .location('HorizontalToolbarRight')
                .component('action-button')
                .id('view_investor_order_config_button')
                .props({
                  variant: 'primary',
                  content: t('app.commands.common.viewOrder.button'),
                  isDisabled: true
                })
          : null
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);
        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .customMenu(
        type === 'context-menu'
          ? (m) =>
              m
                .name(t('app.commands.common.viewOrder.contextMenu'))
                .tabName(t('app.common.grids.contextMenuTabs.action'))
                .priority(110)
                .visible(({ rowData }) => isActive(rowData))
                .primary()
          : null
      )
      .lifecycles('change', 'init', 'onSelectionChanged', 'onRowDataUpdated')
      .onChange<ActionCommandConfig<ActionCommandContracts['view_investor_order']>>(
        viewInvestorOrderActionOnChange
      );

/**
 * Re-useable function to handle the onChange lifecycle of the view investor order action
 * - This function will open the Investor Order View widget
 *
 * @param ctx - The action event
 * @returns Change function
 */
export async function viewInvestorOrderActionOnChange<T extends ViewInvestorOrderActionArgs>(
  ctx: ActionContext<T>
) {
  const { lifecycle, data, notify, container, workspace } = ctx;
  const [selectedRow] = data;
  const {
    id,
    side,
    orderType,
    limitPrice: price,
    totalQuantity: quantity,
    instrumentDisplayCode: symbol
  } = selectedRow || {};

  // -------- Handle button state --------

  const isDisabled = !isActive(data);

  notify({ isDisabled });

  if (lifecycle !== 'change' || isDisabled) {
    return;
  }

  // -------- Handle clicking the button --------

  notify({ isLoading: true });

  try {
    await openViewInvestorOrder({
      windowId: getLeaderOrTabId(container),
      dialogInfo: {
        id,
        side,
        orderType,
        price,
        quantity,
        symbol
      }
    });
  } catch (e) {
    const message = e instanceof Error ? e.message : typeof e === 'string' ? e : 'Unknown';
    const messageCtx = t('app.common.errorWithMessage', { message });
    void openMessageDialog(messageCtx, workspace).catch(console.error);
  }

  notify({ isLoading: false });
}

/**
 * Re-useable function to determine if the button should be active
 *
 * @param rowData - The data from the grid
 * @returns Whether the button should be active
 */
export function isActive(rowData?: ViewInvestorOrderActionArgs[]): boolean {
  if (!rowData) return false;
  if (rowData.length === 0) return false;
  if (rowData.length > 1) return false;
  const [firstSelection] = rowData;
  if (!firstSelection.id) return false;
  return true;
}
