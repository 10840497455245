import { openExecutionView } from '@app/generated/sdk';
import { useCallback } from 'react';
import { useLeaderOrTabId } from '@app/common/workspace/workspace.hooks';
import { getViewDialogTitle } from '@app/common/types/orders/orders.utils';
import type { ViewDialogInfo } from '@app/common/types/orders/orders.utils';

export interface OpenViewExecutionArgs {
  windowId: string;
  dialogInfo: ViewDialogInfo;
}

export const openViewExecution = async ({ windowId, dialogInfo }: OpenViewExecutionArgs) => {
  await openExecutionView(windowId, {
    componentProps: {
      id: dialogInfo.id || ''
    },
    title: getViewDialogTitle(dialogInfo, 'execution')
  });
};

export const useOpenViewExecution = (dialogInfo?: ViewDialogInfo) => {
  const windowId = useLeaderOrTabId();

  return useCallback(() => {
    if (!dialogInfo) {
      console.error('dialogInfo is null or undefined');
      return;
    }
    openViewExecution({ windowId, dialogInfo }).catch((e) => console.error(e));
  }, [windowId, dialogInfo]);
};
